import { BryntumScheduler, BryntumSchedulerProps } from '@bryntum/scheduler-react';
import { Cancel, LocalShipping, Reply, Restore } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Grid, Select, MenuItem } from '@mui/material';
import { FC, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { DateScroller } from '../../Components/CommonInputs';
import { SlimFormHeader } from '../../Components/Core/SlimFormHeader';
import './Dispatches.scss';
import { HaulerManagementDispatchingView } from './Views/HaulerDispatching/HaulerManagementDispatchingView';
import { OrderDispatchingView } from './Views/OrderDispatching/OrderDispatchingView';
import {
    useArchiveDispatchBulkDeleteFromDatabaseMutation,
    useLazyGetDispatchOrderManagementCurrentMonthQuery,
    usePatchDispatchCancelMutation,
    usePatchDispatchRestoreMutation,
    useUpdateDispatchBulkRecallMutation,
    useUpdateDispatchBulkReleaseMutation,
} from '../../store/generated/generatedApi';
import { EventModel, Popup, StateProvider } from '@bryntum/scheduler';
import { DriverManagementEventBar, OrderManagementEventBar } from './Components';
import { WebhookConnector } from '../../Components/Webhook/WebhookConnector';
import { getToolTipData, isNotOutOfDate } from './DispatchesHelper';
import { ConfirmationDialogWithInput } from '../../Components/Core/ConfirmDialog/ConfirmationDialogWithInput';
import { endOfDay, format, isValid, startOfDay } from 'date-fns';
import { convertYYYYMMDDToDate } from '../../util';
import { RestoreDispatchesDialog } from './RestoreDispatchesDialog';

export interface IDispatchesProps {
    webhookConnector: WebhookConnector;
}

export const Dispatches: FC<IDispatchesProps> = (props) => {
    const { webhookConnector } = props;
    const [deleteDispatches] = useArchiveDispatchBulkDeleteFromDatabaseMutation();
    const [releaseDispatches] = useUpdateDispatchBulkReleaseMutation();
    const [recallDispatches] = useUpdateDispatchBulkRecallMutation();
    const [cancelDispatches] = usePatchDispatchCancelMutation();
    const [restoreDispatches] = usePatchDispatchRestoreMutation();

    const [searchParams, setSearchParams] = useSearchParams();
    const dateParam = searchParams.get('date');
    const viewParam = searchParams.get('view');

    const [date, setDate] = useState(dateParam ? startOfDay(convertYYYYMMDDToDate(dateParam)) : startOfDay(new Date()));
    const [isHaulerView, setIsDriverView] = useState(viewParam !== null ? viewParam === 'true' : false);
    const [isEditEventOpen, setEditEventOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<EventModel>();

    const [eventsToCancel, setEventsToCancel] = useState<string[]>([]);
    const [isCancelMenuOpen, setIsCancelMenuOpen] = useState(false);

    const haulerSchedulerRef = useRef<BryntumScheduler>() as MutableRefObject<BryntumScheduler>;
    const orderSchedulerRef = useRef<BryntumScheduler>() as MutableRefObject<BryntumScheduler>;

    const [releaseButtonDisabled, setReleaseButtonDisabled] = useState(true);
    const [recallButtonDisabled, setRecallButtonDisabled] = useState(true);
    const [selectAllButtonDisabled, setSelectAllButtonDisabled] = useState(false);
    const [deselectAllButtonDisabled, setDeselectAllButtonDisabled] = useState(true);

    const [selectedOrderSchedulerRefEvents, setSelectedOrderSchedulerRefEvents] = useState<EventModel[]>([]);
    const [selectedHaulerSchedulerRefEvents, setSelectedHaulerSchedulerRefEvents] = useState<EventModel[]>([]);

    const [showRestoreModal, setShowRestoreModal] = useState(false);
    StateProvider.setup('local');

    const [getCurrentMonthDispatch, { data, isFetching }] = useLazyGetDispatchOrderManagementCurrentMonthQuery();
    const [daysWithData, setDaysWithData] = useState<string[]>([]);
    const [month, setMonth] = useState(date);

    const loadData = useCallback(
        (date: Date) => {
            const formattedDate = format(date, 'yyyy-MM');
            getCurrentMonthDispatch({ startDate: formattedDate });
        },
        [getCurrentMonthDispatch]
    );

    useEffect(() => {
        loadData(month);
    }, [month, loadData]);

    useEffect(() => {
        if (!isFetching) {
            setDaysWithData(data ?? []);
        }
    }, [data, isFetching]);

    const onMonthChange = (newMonth: Date) => {
        setDaysWithData([]);
        setMonth(newMonth);
    };

    const canModifyEvent = useCallback((source: any, event: any) => {
        let canModify = true;
        if (!isNotOutOfDate(event.startDate) || event.isCompleted || event.isOrderInReview) {
            canModify = false;
        }

        return canModify;
    }, []);

    const [schedulerConfig] = useState<BryntumSchedulerProps>({
        startDate: startOfDay(date),
        endDate: endOfDay(date),
        timeZone: 'america/new_york',
        sortFeature: {
            multiSort: false,
        },
        eventTooltipFeature: false,
        barMargin: 1,
        stripeFeature: true,
        timeRangesFeature: false,
        zoomOnMouseWheel: false,
        zoomOnTimeAxisDoubleClick: false,
        zoomKeepsOriginalTimespan: true,
        headerZoomFeature: false,
        timeAxisHeaderMenuFeature: false,
        viewPreset: {
            base: 'hourAndDay',
            defaultSpan: 24,
        },
        createEventOnDblClick: false,
        eventDragFeature: {
            constrainDragToTimeline: true,
        },
        eventDragCreateFeature: {
            disabled: true,
        },
        onEventClick({ source, eventRecord, event }) {
            if (event.metaKey || event.ctrlKey || event.altKey) {
                return;
            }
            new Popup({
                forElement: event.target as any,
                cls: 'b-demo-unavailable',
                header: { title: 'Dispatch Information', cls: (eventRecord as any).driverId ? 'driver' : 'broker' },
                width: '70em',
                html: getToolTipData(source.startDate, (eventRecord as any)?.data, eventRecord),
                closeAction: 'destroy',
                scrollAction: 'realign',
                closable: true,
                tools: {
                    edit: {
                        type: 'tool',
                        cls: 'b-icon b-icon-edit custom-edit-icon',
                        onClick: () => {
                            setSelectedEvent(eventRecord);
                            setEditEventOpen(true);
                        },
                    },
                },
            });
        },
        multiEventSelect: true,
        eventMenuFeature: {
            items: {
                viewEvent: {
                    text: 'View',
                    icon: 'b-fa b-fa-fw b-fa-eye',
                    weight: 100,
                },
                releaseEvent: false,
                recallEvent: false,
                editEvent: {
                    text: 'Edit',
                    weight: 300,
                },
                cancelEvent: {
                    text: 'Cancel',
                    icon: 'b-fa b-fa-fw b-fa-ban',
                    weight: 400,
                },
                deleteEvent: {
                    text: 'Delete',
                    weight: 500,
                },
                cutEvent: false,
                copyEvent: false,
                unassignEvent: false,
            },
        },
        eventEditFeature: {
            items: {
                nameField: null,
                resourceField: null,
                startDateField: null,
                endDateField: null,
            },
        },
        scheduleMenuFeature: {
            items: {
                addEvent: false,
            },
        },
        listeners: {
            beforeSelectionChange: (event) => {
                if (event?.selected?.data?.isCompleted || event?.selected?.data?.isOrderInReview) {
                    return false;
                }
            },
            beforeEventDrag: ({ source, eventRecord }) => {
                return canModifyEvent(source, eventRecord);
            },
            beforeEventAdd: ({ source }) => {
                return isNotOutOfDate(source.startDate);
            },
            beforeEventResize: ({ source, eventRecord }) => {
                return canModifyEvent(source, eventRecord);
            },
            beforeEventDelete(event) {
                if (isNotOutOfDate(event.source.startDate)) {
                    const removedEvents = event?.eventRecords as any[];
                    if (removedEvents.filter((x) => x.data.isCompleted || x.data.isOrderInReview).length > 0) {
                        return false;
                    }
                    const idsToDelete = removedEvents.map((removedEvent) => removedEvent.data.id);
                    deleteDispatches(idsToDelete);
                } else {
                    return false;
                }
            },
            beforeEventEdit({ source, eventRecord }) {
                const canEdit = canModifyEvent(source, eventRecord);

                if (canEdit) {
                    setSelectedEvent(eventRecord);
                    setEditEventOpen(true);
                }

                return false;
            },
        }
    });

    const orderManagementEventRenderer = useCallback(({ eventRecord, resourceRecord, assignmentRecord, renderData }) => {
        if (!(eventRecord as any).released) {
            renderData.eventStyle = 'hollow';
        }
        renderData.style = 'font-weight: 800';

        return <OrderManagementEventBar key={assignmentRecord.id} eventRecord={eventRecord} resourceRecord={resourceRecord} />;
    }, []);

    const driverManagementEventRenderer = useCallback(({ eventRecord, resourceRecord, assignmentRecord, renderData }) => {
        if (!(eventRecord as any).released) {
            renderData.eventStyle = 'hollow';
        }
        renderData.style = 'font-weight: 800';

        return <DriverManagementEventBar key={assignmentRecord.id} eventRecord={eventRecord} resourceRecord={resourceRecord} />;
    }, []);

    //order management toggle left side
    const [orderConfig] = useState<BryntumSchedulerProps>({
        ...schedulerConfig,
        stateId: 'orderScheduler',
        eventRenderer: orderManagementEventRenderer,
        listeners: {
            ...schedulerConfig.listeners,
            eventSelectionChange({ selection }) {
                const uncompletedEvents = selection.filter((event) => !event.data.isCompleted && !event.data.isOrderInReview);
                setSelectedOrderSchedulerRefEvents(uncompletedEvents);
            },
        },
        groupFeature: {
            field: 'orderNumber',
            ascending: true,
        },
    });

    //Driver management toggle left side
    const [haulerConfig] = useState<BryntumSchedulerProps>({
        ...schedulerConfig,
        stateId: 'haulerScheduler',
        eventRenderer: driverManagementEventRenderer,
        listeners: {
            ...schedulerConfig.listeners,
            eventSelectionChange({ selection }) {
                const uncompletedEvents = selection.filter((event) => !event.data.isCompleted && !event.data.isOrderInReview);
                setSelectedHaulerSchedulerRefEvents(uncompletedEvents);
            },
        },
        groupFeature: {
            field: 'haulerType',
            ascending: false,
        },
    });

    useEffect(() => {
        let releasedEvents = [] as EventModel[];
        let unreleasedEvents = [] as EventModel[];

        releasedEvents = releasedEvents.concat(selectedOrderSchedulerRefEvents.filter((x) => (x as any).released));
        releasedEvents = releasedEvents.concat(selectedHaulerSchedulerRefEvents.filter((x) => (x as any).released));

        unreleasedEvents = unreleasedEvents.concat(selectedOrderSchedulerRefEvents.filter((x) => !(x as any).released));
        unreleasedEvents = unreleasedEvents.concat(selectedHaulerSchedulerRefEvents.filter((x) => !(x as any).released));

        if (releasedEvents.length > 0 || unreleasedEvents.length > 0) {
            setDeselectAllButtonDisabled(false);
            if (releasedEvents.length > 0) {
                setRecallButtonDisabled(false);
            } else {
                setRecallButtonDisabled(true);
            }

            if (unreleasedEvents.length > 0) {
                setReleaseButtonDisabled(false);
            } else {
                setReleaseButtonDisabled(true);
            }

            if (isHaulerView) {
                if (selectedHaulerSchedulerRefEvents.length === (haulerSchedulerRef?.current?.instance?.events?.length ?? 0)) {
                    setSelectAllButtonDisabled(true);
                } else {
                    setSelectAllButtonDisabled(false);
                }
            } else {
                if (selectedOrderSchedulerRefEvents.length === (orderSchedulerRef?.current?.instance?.events?.length ?? 0)) {
                    setSelectAllButtonDisabled(true);
                } else {
                    setSelectAllButtonDisabled(false);
                }
            }
        } else {
            setDeselectAllButtonDisabled(true);
            setSelectAllButtonDisabled(false);
            setRecallButtonDisabled(true);
            setReleaseButtonDisabled(true);
        }
    }, [isHaulerView, selectedHaulerSchedulerRefEvents, selectedOrderSchedulerRefEvents]);

    const selectAll = useCallback(() => {
        if (isHaulerView) {
            const events = haulerSchedulerRef?.current.instance.events as any[];
            haulerSchedulerRef?.current?.instance.selectEvents(events.filter((event) => !event.data.isCompleted && !event.data.isOrderInReview));
        } else {
            const events = orderSchedulerRef?.current.instance.events as any[];
            orderSchedulerRef?.current?.instance.selectEvents(events.filter((event) => !event.data.isCompleted && !event.data.isOrderInReview));
        }
    }, [isHaulerView]);

    const deselectAll = useCallback(() => {
        haulerSchedulerRef?.current?.instance.clearEventSelection();
        orderSchedulerRef?.current?.instance.clearEventSelection();
        setSelectAllButtonDisabled(false);
        setDeselectAllButtonDisabled(true);
        setReleaseButtonDisabled(true);
        setRecallButtonDisabled(true);
    }, []);

    const handleCancelDispatches = useCallback(
        (reason: string) => {
            if (eventsToCancel.length > 0) {
                cancelDispatches({ canceledItems: eventsToCancel.map((id) => ({ id, reason })) });
            }

            if (eventsToCancel.length === 0) {
                setIsCancelMenuOpen(false);
            }

            if (isHaulerView) {
                haulerSchedulerRef?.current?.instance.clearEventSelection();
                setSelectedHaulerSchedulerRefEvents([]);
            } else {
                orderSchedulerRef?.current?.instance.clearEventSelection();
                setSelectedOrderSchedulerRefEvents([]);
            }
            setSelectedEvent(undefined);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            cancelDispatches,
            eventsToCancel,
            isHaulerView,
            orderSchedulerRef,
            haulerSchedulerRef,
            orderSchedulerRef?.current?.instance,
            haulerSchedulerRef?.current?.instance,
        ]
    );

    const cancelSelectedEvents = useCallback(
        (selectedId?: string) => {
            let idsToUpdate = [] as string[];
            const updateDispatches = (ref: MutableRefObject<BryntumScheduler>) => {
                let updatedIds = [] as string[];
                const selectedEvents = ref.current.instance.selectedEvents;
                const filteredEvents = !!selectedId ? (ref.current.instance.events.filter((x) => x.id === selectedId) as EventModel[]) : selectedEvents;

                if (filteredEvents.length > 0) {
                    filteredEvents.forEach((event) => {
                        event?.id && updatedIds.push(event.id! as string);
                    });
                }
                return updatedIds;
            };

            if (isHaulerView) {
                if (haulerSchedulerRef?.current?.instance?.selectedEvents) {
                    idsToUpdate = idsToUpdate.concat(updateDispatches(haulerSchedulerRef));
                }
            } else {
                if (orderSchedulerRef?.current?.instance?.selectedEvents) {
                    idsToUpdate = updateDispatches(orderSchedulerRef);
                }
            }

            if (idsToUpdate.length > 0) {
                setIsCancelMenuOpen(true);
                setEventsToCancel(idsToUpdate);
            } else {
                setIsCancelMenuOpen(false);
                setEventsToCancel([]);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isHaulerView, orderSchedulerRef, haulerSchedulerRef, orderSchedulerRef?.current?.instance, haulerSchedulerRef?.current?.instance]
    );

    const releaseOrRecallSelectedEvents = useCallback(
        (release: boolean, selectedId?: string) => {
            let idsToUpdate = [] as string[];

            const updateDispatches = (ref: MutableRefObject<BryntumScheduler>) => {
                let updatedIds = [] as string[];
                const selectedEvents = ref.current.instance.selectedEvents;
                const filteredEvents = !!selectedId ? (ref.current.instance.events.filter((x) => x.id === selectedId) as EventModel[]) : selectedEvents;

                if (filteredEvents.length > 0) {
                    ref.current.instance.eventStore.beginBatch();
                    filteredEvents.forEach((event) => {
                        event?.id && updatedIds.push(event.id! as string);
                        event.set('released', release);
                    });
                    ref.current.instance.eventStore.endBatch();
                    ref.current.instance.eventStore.commit();
                }
                return updatedIds;
            };

            if (isHaulerView) {
                if (haulerSchedulerRef?.current?.instance?.selectedEvents) {
                    idsToUpdate = idsToUpdate.concat(updateDispatches(haulerSchedulerRef));
                }
            } else {
                if (orderSchedulerRef?.current?.instance?.selectedEvents) {
                    idsToUpdate = updateDispatches(orderSchedulerRef);
                }
            }

            if (release) {
                releaseDispatches(idsToUpdate);
            } else {
                recallDispatches(idsToUpdate);
            }

            if (isHaulerView) {
                haulerSchedulerRef?.current?.instance.clearEventSelection();
                setSelectedHaulerSchedulerRefEvents([]);
            } else {
                orderSchedulerRef?.current?.instance.clearEventSelection();
                setSelectedOrderSchedulerRefEvents([]);
            }
            setSelectedEvent(undefined);
        },
        [isHaulerView, recallDispatches, releaseDispatches]
    );

    const updateDate = useCallback(
        (updatedDate: Date) => {
            const newDate = new Date(updatedDate);
            if (isValid(newDate)) {
                const startDate = startOfDay(newDate);
                setDate(startDate);
                setSearchParams(createSearchParams({ date: format(startDate, 'yyyy-MM-dd'), view: isHaulerView.toString() }));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setSearchParams, isHaulerView, orderSchedulerRef, haulerSchedulerRef, orderSchedulerRef?.current?.instance, haulerSchedulerRef?.current?.instance]
    );

    useEffect(() => {
        const config = {
            processItems({ eventRecord, items }) {
                if (eventRecord.released) {
                    items.recallEvent = {
                        text: 'Recall',
                        icon: 'b-fa b-fa-fw b-fa-reply',
                        weight: 200,
                        onItem({ eventRecord }) {
                            releaseOrRecallSelectedEvents(false, eventRecord.id);
                        },
                    };
                } else {
                    items.releaseEvent = {
                        text: 'Release',
                        icon: 'b-fa b-fa-fw b-fa-share',
                        weight: 200,
                        onItem({ eventRecord }) {
                            releaseOrRecallSelectedEvents(true, eventRecord.id);
                        },
                    };
                }
            },
            items: {
                viewEvent: {
                    text: 'View',
                    icon: 'b-fa b-fa-fw b-fa-eye',
                    weight: 100,
                    onItem({ source, eventRecord, event }) {
                        new Popup({
                            forElement: event.target as any,
                            cls: 'b-demo-unavailable',
                            header: { title: 'Dispatch Information', cls: (eventRecord as any).driverId ? 'driver' : 'broker' },
                            width: '70em',
                            html: getToolTipData(source.startDate, (eventRecord as any)?.data, eventRecord),
                            closeAction: 'destroy',
                            scrollAction: 'realign',
                        });
                    },
                },
                releaseEvent: false,
                recallEvent: false,
                editEvent: {
                    text: 'Edit',
                    weight: 300,
                },
                cancelEvent: {
                    text: 'Cancel',
                    icon: 'b-fa b-fa-fw b-fa-ban',
                    weight: 400,
                    onItem({ eventRecord }) {
                        cancelSelectedEvents(eventRecord.id);
                    },
                },
                deleteEvent: {
                    text: 'Delete',
                    weight: 500,
                },
                cutEvent: false,
                copyEvent: false,
                unassignEvent: false,
            },
        };

        orderSchedulerRef?.current?.instance?.features?.eventMenu?.setConfig({
            disabled: !isNotOutOfDate(date),
            ...config,
        });

        haulerSchedulerRef?.current?.instance?.features?.eventMenu?.setConfig({
            disabled: !isNotOutOfDate(date),
            ...config,
        });
    }, [
        date,
        orderSchedulerRef,
        haulerSchedulerRef,
        orderSchedulerRef?.current?.instance,
        haulerSchedulerRef?.current?.instance,
        releaseOrRecallSelectedEvents,
        cancelSelectedEvents,
    ]);

    const [viewType, setViewType] = useState('Order Management');

    const handleViewChange = useCallback((event) => {
        deselectAll();
        setViewType(event.target.value);
        setIsDriverView(event.target.value === 'Driver Management');
        setSearchParams(createSearchParams({ date: format(date, 'yyyy-MM-dd'), view: (!isHaulerView).toString() }));
    }, [date, deselectAll, isHaulerView, setSearchParams]);

    return (
        <div style={{ height: 'calc(-72px + 100vh)' }}>
            <ConfirmationDialogWithInput
                open={isCancelMenuOpen}
                onClose={() => {
                    setIsCancelMenuOpen(false);
                }}
                formLabel={'Please enter a cancelation reason.'}
                title={`Cancel Dispatch(es)`}
                titleIcon={<Cancel />}
                cancelText={'Cancel'}
                confirmText={'Save'}
                save={handleCancelDispatches}
                requireConfirmation={false}
            />
            <RestoreDispatchesDialog
                open={showRestoreModal}
                onConfirm={(selectedDispatches) => {
                    const ids = selectedDispatches.map((x) => x.id);
                    restoreDispatches(ids).then(() => setShowRestoreModal(false));
                }}
                onClose={() => setShowRestoreModal(false)}
                startDate={date}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <SlimFormHeader
                    hideDividers
                    objectName='Dispatch'
                    permissionsTypeName={'dispatch'}
                    icon={<LocalShipping />}
                    title={'Dispatch'}
                    breadcrumbs={[{ label: 'Home', navLink: '/' }]}
                    entityNameSingular={'dispatch'}
                    additionalHeaderItems={
                        <>
                            <Grid item>
                                <ButtonGroup variant='contained' color='primary'>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        startIcon={<Restore />}
                                        onClick={() => setShowRestoreModal(true)}
                                        disabled={!isNotOutOfDate(date)}>
                                        Restore
                                    </Button>
                                    <Button
                                        startIcon={<Cancel sx={{ transform: 'scaleX(-1)' }} />}
                                        variant='contained'
                                        color='primary'
                                        onClick={() => cancelSelectedEvents()}
                                        disabled={!isNotOutOfDate(date) || deselectAllButtonDisabled}>
                                        Cancel
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid item>
                                <ButtonGroup variant='contained' color='primary'>
                                    <Button
                                        startIcon={<Reply sx={{ transform: 'scaleX(-1)' }} />}
                                        variant='contained'
                                        color='primary'
                                        onClick={() => releaseOrRecallSelectedEvents(true)}
                                        disabled={!isNotOutOfDate(date) || releaseButtonDisabled}>
                                        Release
                                    </Button>
                                    <Button
                                        startIcon={<Reply />}
                                        variant='contained'
                                        color='primary'
                                        onClick={() => releaseOrRecallSelectedEvents(false)}
                                        disabled={!isNotOutOfDate(date) || recallButtonDisabled}>
                                        Recall
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid item>
                                <ButtonGroup variant='contained' color='primary'>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={deselectAll}
                                        disabled={!isNotOutOfDate(date) || deselectAllButtonDisabled}>
                                        Deselect All
                                    </Button>
                                    <Button variant='contained' color='primary' onClick={selectAll} disabled={!isNotOutOfDate(date) || selectAllButtonDisabled}>
                                        Select All
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid item>
                                <Select value={viewType} onChange={handleViewChange}>
                                    <MenuItem value='Order Management'>Order Management</MenuItem>
                                    <MenuItem value='Driver Management'>Driver Management</MenuItem>
                                </Select>
                            </Grid>
                            <DateScroller date={date} updateDate={updateDate} daysWithData={daysWithData} handleOnMonthChange={onMonthChange} />
                        </>
                    }
                />
                {isHaulerView === false ? (
                    <OrderDispatchingView
                        date={date}
                        isEditEventOpen={isEditEventOpen}
                        schedulerConfig={orderConfig}
                        schedulerRef={orderSchedulerRef}
                        selectedEvent={selectedEvent}
                        setEditEventOpen={setEditEventOpen}
                        setSelectedEvent={setSelectedEvent}
                        webhookConnector={webhookConnector}
                    />
                ) : (
                    <HaulerManagementDispatchingView
                        date={date}
                        isEditEventOpen={isEditEventOpen}
                        schedulerConfig={haulerConfig}
                        schedulerRef={haulerSchedulerRef}
                        selectedEvent={selectedEvent}
                        setEditEventOpen={setEditEventOpen}
                        setSelectedEvent={setSelectedEvent}
                        webhookConnector={webhookConnector}
                    />
                )}
            </Box>
        </div>
    );
};
