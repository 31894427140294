import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Box, Button, Card, CardContent, CardHeader, Collapse, Grid, IconButton, IconButtonProps, Link, styled, Typography, useTheme } from '@mui/material';
import { ExpandMore, LocationOn } from '@mui/icons-material';
import { PersonalDispatchDto } from '../../dtos';
import { Stack } from '@mui/system';
import { LineItemTypes, formatCurrency } from '../../util';
import { usePatchDispatchArriveMutation, usePatchDispatchDepartMutation, usePatchDispatchStartMutation, usePatchDispatchStopMutation } from '../../store/generated/generatedApi';
import { useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { format } from 'date-fns';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMoreButton = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export interface IDispatchCardProps extends PropsWithChildren {
    dispatch: PersonalDispatchDto;
    isOpenInitially?: boolean;
    isReadonly?: boolean;
    isActiveDispatch?: boolean;
    hideExpandMoreButton?: boolean;
}

export const DispatchCard: FC<IDispatchCardProps> = (props) => {
    const { dispatch, isOpenInitially = false, isReadonly, isActiveDispatch, hideExpandMoreButton } = props;
    const [startDispatch, { isLoading: isStartingDispatch, isError: isErrorStartingDispatch, reset: resetStartDispatch }] = usePatchDispatchStartMutation();
    useFailedActionSnackbar('start', 'dispatch', isErrorStartingDispatch, resetStartDispatch);
    const [stopDispatch, { isLoading: isStoppingDispatch, isError: isErrorStoppingDispatch, reset: resetStopDispatch }] = usePatchDispatchStopMutation();
    useFailedActionSnackbar('stop', 'dispatch', isErrorStoppingDispatch, resetStopDispatch);
    const [markDispatchArrival, { isLoading: isMarkingDispatchArrival, isError: isErrorMarkingDispatchArrival, reset: resetMarkDispatchArrival }] = usePatchDispatchArriveMutation();
    useFailedActionSnackbar('mark', 'dispatch arrival', isErrorMarkingDispatchArrival, resetMarkDispatchArrival);
    const [markDispatchDeparture, { isLoading: isMarkingDispatchDeparture, isError: isErrorMarkingDispatchDeparture, reset: resetMarkDispatchDeparture }] = usePatchDispatchDepartMutation();
    useFailedActionSnackbar('mark', 'dispatch departure', isErrorMarkingDispatchDeparture, resetMarkDispatchDeparture);
    const [expanded, setExpanded] = useState(isOpenInitially);
    const theme = useTheme();

    const startButtonText = useMemo(() => {
        if (dispatch.lineItemType === LineItemTypes.Hourly) {
            return 'Begin Hourly';
        } else {
            return 'Start';
        }
    }, [dispatch]);

    const endButtonText = useMemo(() => {
        if (dispatch.lineItemType === LineItemTypes.Hourly) {
            return 'End Hourly';
        } else {
            return 'Done Hauling';
        }
    }, [dispatch]);

    const handleExpandClick = useCallback(() => {
        if (hideExpandMoreButton) {
            return;
        }
        setExpanded(!expanded);
    }, [expanded, hideExpandMoreButton]);

    const onStartButtonClicked = useCallback((e: any) => {
        e.stopPropagation();
        startDispatch({ dispatchId: dispatch.id });
    }, [startDispatch, dispatch]);

    const onStopButtonClicked = useCallback((e: any) => {
        e.stopPropagation();
        stopDispatch({ dispatchId: dispatch.id });
    }, [stopDispatch, dispatch]);

    const onArriveButtonClicked = useCallback((e: any) => {
        e.stopPropagation();
        markDispatchArrival({ dispatchId: dispatch.id });
    }, [markDispatchArrival, dispatch]);

    const onDepartButtonClicked = useCallback((e: any) => {
        e.stopPropagation();
        markDispatchDeparture({ dispatchId: dispatch.id });
    }, [markDispatchDeparture, dispatch]);

    const departureStatusControls = useMemo(() => {
        switch (dispatch.departureStatus) {
            case 'Arrived':
                return (
                    <Grid item m={2}>
                        <Button
                            variant='contained'
                            color='primary'
                            sx={{ width: '100%' }}
                            disabled={isReadonly || isMarkingDispatchDeparture}
                            onClick={onDepartButtonClicked}>
                            Depart
                        </Button>
                    </Grid>
                )
            case 'Departed':
                return (
                    <Grid item m={2}>
                        <Button
                            variant='contained'
                            color='primary'
                            sx={{ width: '100%' }}
                            disabled={isReadonly || isMarkingDispatchArrival}
                            onClick={onArriveButtonClicked}>
                            Arrive
                        </Button>
                    </Grid>
                );
            default:
                return null;
        }
    }, [isReadonly, isMarkingDispatchDeparture, onDepartButtonClicked, isMarkingDispatchArrival, onArriveButtonClicked, dispatch.departureStatus]);

    return (
        <Card variant='outlined' sx={{ height: expanded ? '100%' : 'auto' }}>
            <CardHeader
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                }}
                onClick={handleExpandClick}
                title={
                    <Grid container direction='row' alignItems='center'>
                        <Grid item container direction='column' xs={10}>
                            <Grid item zeroMinWidth>
                                <Typography variant='h6' noWrap>
                                    {dispatch.description}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    {dispatch.customerName}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                action={
                    <ExpandMoreButton
                        expand={expanded}
                        size='small'
                        disableRipple
                        sx={{
                            color: theme.palette.primary.main,
                            backgroundColor: 'white !important',
                            marginTop: '15px',
                            visibility: hideExpandMoreButton ? 'hidden' : undefined
                        }}>
                        <ExpandMore />
                    </ExpandMoreButton>
                }
            />
            <Collapse in={expanded} timeout='auto' unmountOnExit>
                <CardContent
                    sx={{
                        padding: '8px',
                        paddingBottom: '0px !important',
                    }}>
                    <Box>
                        <Grid container direction='column' onClick={handleExpandClick}>
                            <Grid item>
                                <Typography fontWeight='bold'>{dispatch.projectName}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>{dispatch.jobName}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    Job #{dispatch.jobNumber} - Order #{dispatch.orderNumber} - Line Item #{dispatch.lineItemNumber}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    Your Equipment: {dispatch.yourEquipment}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>PO Number: {dispatch.poNumber}</Typography>
                            </Grid>
                            {dispatch.driverMemo && <Grid item>
                                <Typography>Driver Memo: {dispatch.driverMemo}</Typography>
                            </Grid>}
                            <Grid item>
                                <Typography>
                                    Rate: {formatCurrency(dispatch.rate)}{dispatch.isBilledToCustomer && ' (Billed to Customer)'}
                                </Typography>
                            </Grid>
                            {dispatch.driverRate && <Grid item>
                                <Typography>Driver Rate: {formatCurrency(dispatch.driverRate)}</Typography>
                            </Grid>}
                            {dispatch.brokerRate && <Grid item>
                                <Typography>Broker Rate: {formatCurrency(dispatch.brokerRate)}</Typography>
                            </Grid>}
                            <Grid item mt={1}>
                                <Typography fontWeight='bold'>Times</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Onsite Time: {format(dispatch.arrivalTime, 'hh:mm aa')}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Yard Time: {dispatch.yardTime !== null ? format(dispatch.yardTime ?? '', 'hh:mm aa') : 'ASAP'}</Typography>
                            </Grid>
                            {dispatch.haulFromAddress && (
                                <>
                                    <Grid item mt={2}>
                                        <Typography fontWeight='bold'>Haul From</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Stack direction='row'>
                                            <LocationOn color='primary' />
                                            <Typography>
                                                <Link
                                                    href={`https://www.google.com/maps/dir/?api=1&destination=${dispatch.haulFromAddress}&travelmode=driving`}
                                                    target='_blank'
                                                    onClick={(e) => e.stopPropagation()}>
                                                    {dispatch.haulFromAddress}
                                                </Link>
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    {dispatch.haulFromSiteName && <Grid item>
                                        <Typography>Site Name: {dispatch.haulFromSiteName}</Typography>
                                    </Grid>}
                                    {dispatch.haulFromRegion && <Grid item>
                                        <Typography>Region: {dispatch.haulFromRegion}</Typography>
                                    </Grid>}
                                </>
                            )}
                            {dispatch.haulToAddress && (
                                <>
                                    <Grid item mt={2}>
                                        <Typography fontWeight='bold'>Haul To</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Stack direction='row'>
                                            <LocationOn color='primary' />
                                            <Typography>
                                                <Link
                                                    href={`https://www.google.com/maps/dir/?api=1&destination=${dispatch.haulToAddress}&travelmode=driving`}
                                                    target='_blank'
                                                    onClick={(e) => e.stopPropagation()}>
                                                    {dispatch.haulToAddress}
                                                </Link>
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    {dispatch.haulToSiteName && <Grid item>
                                        <Typography>Site Name: {dispatch.haulToSiteName}</Typography>
                                    </Grid>}
                                    {dispatch.haulToRegion && <Grid item>
                                        <Typography>Region: {dispatch.haulToRegion}</Typography>
                                    </Grid>}
                                </>
                            )}
                            <Grid item mt={2}>
                                <Typography fontWeight='bold'>Task</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>{dispatch.description}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>{dispatch.task}</Typography>
                            </Grid>
                            <Grid item mt={1}>
                                <Typography fontWeight='bold'>({dispatch.equipmentInfo.length} Additional Drivers on Job)</Typography>
                            </Grid>
                            <Grid item container direction='column'>
                                {dispatch.equipmentInfo?.map((info, idx) => (
                                    <Grid item key={`info-${idx}`}>
                                        <Typography>{info}</Typography>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid item mt={1}>
                                <Typography fontWeight='bold'>Order Contact</Typography>
                            </Grid>
                            {dispatch.orderContact !== null && <Grid item container direction='column'>
                                <Grid item>
                                    <Typography>{dispatch.orderContact?.contactName}{dispatch.orderContact?.cellPhone !== null && dispatch.orderContact?.cellPhone !== '' ? ` - Cell #: ${dispatch.orderContact?.cellPhone}` : ''}{dispatch.orderContact?.landlinePhone !== null && dispatch.orderContact?.landlinePhone !== '' ? ` - Landline #: ${dispatch.orderContact?.landlinePhone}` : ''}{dispatch.orderContact?.email !== null && dispatch.orderContact?.email !== '' ? ` - Email: ${dispatch.orderContact?.email}` : ''}</Typography>
                                </Grid>
                            </Grid>}

                            {isActiveDispatch ? (
                                <>
                                    {departureStatusControls}
                                    <Grid item m={2}>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            sx={{ width: '100%' }}
                                            disabled={isReadonly || isStoppingDispatch}
                                            onClick={onStopButtonClicked}>
                                            {endButtonText}
                                        </Button>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item m={2}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        sx={{ width: '100%' }}
                                        disabled={isReadonly || isStartingDispatch}
                                        onClick={onStartButtonClicked}>
                                        {startButtonText}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </CardContent>
            </Collapse>
        </Card>
    );
};
