import { RateReview, Add } from '@mui/icons-material';
import { Grid, Paper, Select, MenuItem, Tooltip, Button } from '@mui/material';
import { format, isValid, startOfDay } from 'date-fns';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { DateScroller } from '../../Components/CommonInputs';
import { SlimFormHeader } from '../../Components/Core/SlimFormHeader';
import { AuthenticatedComponent, StandardCardWithHeader, UserPermissionContext, userHasPermissions } from '../../Components/CoreLib/library';
import { convertYYYYMMDDToDate } from '../../util';
import { OrderReviewListView } from './OrderReviewListView';
import { OrderReviewOrdersListView } from './OrderReviewOrdersListView';
import { useLazyGetDispatchOrderManagementCurrentMonthQuery, useLazyGetOrderReviewCurrentMonthOrdersQuery } from '../../store/generated/generatedApi';
import { useNavigate } from 'react-router-dom';

export const OrderReviewPage: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dateParam = searchParams.get('date');

    const navigate = useNavigate();

    const [date, setDate] = useState(dateParam ? convertYYYYMMDDToDate(dateParam) : new Date());

    const { permissions } = useContext(UserPermissionContext);
    const isUserAllowedToViewReview = userHasPermissions(['read:review'], permissions, 'and');
    const isUserAllowedToViewBilling = userHasPermissions(['read:billing'], permissions, 'and');

    const [getCurrentMonthOrders, { data: currentMonthOrders, isFetching: isFetchingOrders }] = useLazyGetOrderReviewCurrentMonthOrdersQuery();
    const [getCurrentMonthDispatch, { data: currentMonthDispatches, isFetching: isFetchingDispatches }] = useLazyGetDispatchOrderManagementCurrentMonthQuery();

    const [daysWithData, setDaysWithData] = useState<string[]>([]);
    const [month, setMonth] = useState(date);

    const loadData = useCallback(
        (date: Date) => {
            const formattedDate = format(date, 'yyyy-MM');
            getCurrentMonthDispatch({ startDate: formattedDate });
            getCurrentMonthOrders({ startDate: formattedDate });
        },
        [getCurrentMonthDispatch, getCurrentMonthOrders]
    );

    useEffect(() => {
        loadData(month);
    }, [month, loadData]);

    useEffect(() => {
        if (!isFetchingOrders && !isFetchingDispatches) {
            const currentDaysFilter = [...(currentMonthDispatches ?? []), ...(currentMonthOrders ?? [])];
            setDaysWithData(currentDaysFilter);
        }
    }, [currentMonthDispatches, currentMonthOrders, isFetchingOrders, isFetchingDispatches]);

    const onMonthChange = (newMonth: Date) => {
        setDaysWithData([]);
        setMonth(newMonth);
    };

    const getDefault = useCallback(() => {
        if (isUserAllowedToViewReview) {
            return 'CUSTOMER-SERVICE';
        } else if (isUserAllowedToViewBilling) {
            return 'BILLING';
        } else {
            return '';
        }
    }, [isUserAllowedToViewBilling, isUserAllowedToViewReview]);

    const [viewType, setViewType] = useState(getDefault());

    const [isBillingView, setIsBillingView] = useState(false);

    const handleViewChange = useCallback((event) => {
        if (isUserAllowedToViewBilling && event.target.value === 'BILLING') {
            setViewType('BILLING');
            setIsBillingView(true);
        }
        else if (isUserAllowedToViewReview && event.target.value === 'CUSTOMER-SERVICE') {
            setViewType('CUSTOMER-SERVICE');
            setIsBillingView(false);
        }
        else {
            setViewType('');
        }
    }, [isUserAllowedToViewBilling, isUserAllowedToViewReview]);

    useEffect(() => {
        setSearchParams(createSearchParams({ date: format(date, 'yyyy-MM-dd'), view: viewType }));
    }, [date, viewType, setSearchParams]);

    const updateDate = useCallback(
        (updatedDate: Date) => {
            const newDate = new Date(updatedDate);

            if (isValid(newDate)) {
                setDate(startOfDay(newDate));
                setSearchParams(createSearchParams({ date: format(newDate, 'yyyy-MM-dd'), view: viewType }));
            }
        },
        [viewType, setSearchParams]
    );

    const handleEnterTicket = useCallback(() => {
        navigate('/orderReview/enter-ticket?date=' + format(date, 'yyyy-MM-dd'));
    }, [date, navigate]);

    return (
        <Grid container direction='column'>
            <SlimFormHeader
                objectName=''
                permissionsTypeName={'review'}
                icon={<RateReview />}
                title={'Review'}
                breadcrumbs={[{ label: 'Home', navLink: '/' }]}
                entityNameSingular=''
                hideDividers
                id={''}
                isLoading={false}
                additionalHeaderItems={
                    <>
                        {isBillingView &&
                            <Grid item>
                                <Tooltip title='Enter New Ticket'>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        size='large'
                                        onClick={handleEnterTicket}
                                        startIcon={<Add />}
                                        style={{ whiteSpace: 'nowrap' }}>
                                        ENTER TICKETS
                                    </Button>
                                </Tooltip>
                            </Grid>
                        }
                        <Grid item>
                            <Select value={viewType} onChange={handleViewChange}>
                                {isUserAllowedToViewReview &&
                                    <MenuItem value='CUSTOMER-SERVICE'>Customer Service</MenuItem>
                                }
                                {isUserAllowedToViewBilling &&
                                    <MenuItem value='BILLING'>Billing</MenuItem>
                                }
                            </Select>
                        </Grid>
                        <Grid item container direction='row' alignItems='center' gap={1}>
                            <DateScroller date={date} updateDate={updateDate} fullWidth daysWithData={daysWithData} handleOnMonthChange={onMonthChange} />
                        </Grid>
                    </>
                }
            />
            <Grid item p={2}>
                <Paper square variant='outlined'>
                    {!isBillingView ? (
                        <AuthenticatedComponent
                            requiredPermissions={['read:review']}
                            children={
                                <Grid container direction='column' spacing={2}>
                                    <Grid item container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <StandardCardWithHeader headerTitle='Orders Awaiting Review' headerIcon={<RateReview />}>
                                                <OrderReviewOrdersListView date={date} />
                                            </StandardCardWithHeader>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <StandardCardWithHeader headerTitle='In Progress Reviews' headerIcon={<RateReview />}>
                                                <OrderReviewListView date={date} readyForBilling={false} sentToQuickBooks={false} />
                                            </StandardCardWithHeader>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <StandardCardWithHeader headerTitle='Completed Reviews' headerIcon={<RateReview />}>
                                            <OrderReviewListView date={date} readyForBilling sentToQuickBooks={false} />
                                        </StandardCardWithHeader>
                                    </Grid>
                                </Grid>
                            }
                        />
                    ) : (
                        <AuthenticatedComponent
                            requiredPermissions={['read:billing']}
                            children={
                                <Grid container direction='column' spacing={2}>
                                    <Grid item xs={12}>
                                        <StandardCardWithHeader headerTitle='Orders Awaiting Review / In Progress Reviews' headerIcon={<RateReview />}>
                                            <OrderReviewListView date={date} readyForBilling sentToQuickBooks={false} />
                                        </StandardCardWithHeader>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <StandardCardWithHeader headerTitle='Completed Reviews' headerIcon={<RateReview />}>
                                            <OrderReviewListView date={date} readyForBilling sentToQuickBooks />
                                        </StandardCardWithHeader>
                                    </Grid>
                                </Grid>
                            }
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};
