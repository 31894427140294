import { Button, ButtonGroup, Grid } from '@mui/material';
import { RateToggle } from '../../../../dtos';
import { FormNumberInput } from '../../../CoreLib/library';

export interface IRateInputFormProps {
    haulingRate?: number;
    setHaulingRate: (value?: number) => void;
    haulingRateFieldName: string;
    minimumFlatRate?: number;
    setMinimumFlatRate: (value?: number) => void;
    minimumFlatRateFieldName: string;
    driverRate?: number;
    setDriverRate: (value?: number) => void;
    driverRateFieldName: string;
    driverFlatRate?: number;
    setDriverFlatRate: (value?: number) => void;
    driverFlatRateFieldName: string;
    brokerRate?: number;
    setBrokerRate: (value?: number) => void;
    brokerRateFieldName: string;
    brokerFlatRate?: number;
    setBrokerFlatRate: (value?: number) => void;
    brokerFlatRateFieldName: string;
    rateToggle: RateToggle;
    setRateToggle: (rateToggle: RateToggle) => void;
    fieldErrors: Map<any, string>;
    disabled?: boolean;
}

export const RateInputForm: React.FC<IRateInputFormProps> = (props) => {
    const {
        haulingRate,
        setHaulingRate,
        haulingRateFieldName,
        minimumFlatRate,
        setMinimumFlatRate,
        minimumFlatRateFieldName,
        driverRate,
        setDriverRate,
        driverRateFieldName,
        driverFlatRate,
        setDriverFlatRate,
        driverFlatRateFieldName,
        brokerRate,
        setBrokerRate,
        brokerRateFieldName,
        brokerFlatRate,
        setBrokerFlatRate,
        brokerFlatRateFieldName,
        rateToggle,
        setRateToggle,
        fieldErrors,
        disabled,
    } = props;

    return (
        <Grid item container direction='column' xs={12}>
            <Grid item xs={12}>
                <ButtonGroup fullWidth>
                    <Button
                        variant={rateToggle !== RateToggle.Flat ? 'contained' : 'outlined'}
                        color='primary'
                        onClick={() => {
                            switch (rateToggle) {
                                case RateToggle.Both:
                                    setHaulingRate(0);
                                    setDriverRate(0);
                                    setBrokerRate(0);
                                    setRateToggle(RateToggle.Flat);
                                    break;
                                case RateToggle.Flat:
                                    setRateToggle(RateToggle.Both);
                                    break;
                                default:
                                    setRateToggle(RateToggle.Standard);
                                    break;
                            }
                        }}
                        disabled={disabled}>
                        Standard
                    </Button>
                    <Button
                        variant={rateToggle !== RateToggle.Standard ? 'contained' : 'outlined'}
                        color='primary'
                        onClick={() => {
                            switch (rateToggle) {
                                case RateToggle.Both:
                                    setMinimumFlatRate(undefined);
                                    setDriverFlatRate(undefined);
                                    setBrokerFlatRate(undefined);
                                    setRateToggle(RateToggle.Standard);
                                    break;
                                case RateToggle.Standard:
                                    setRateToggle(RateToggle.Both);
                                    break;
                                default:
                                    setRateToggle(RateToggle.Flat);
                                    break;
                            }
                        }}
                        disabled={disabled}>
                        Flat
                    </Button>
                </ButtonGroup>
            </Grid>
            <Grid item container spacing={1}>
                {rateToggle !== RateToggle.Flat && (
                    <Grid item xs={rateToggle === RateToggle.Both ? 6 : 12}>
                        <FormNumberInput
                            value={haulingRate ?? 0}
                            onChange={(event) => setHaulingRate(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber)}
                            label={'Hauling Rate'}
                            name='haulingRate'
                            errorText={fieldErrors.get(haulingRateFieldName)}
                            error={!!fieldErrors.get(haulingRateFieldName)}
                            fullWidth
                            required
                            disabled={disabled}
                            inputProps={{ min: 0 }}
                            isCurrency
                        />
                    </Grid>
                )}
                {rateToggle !== RateToggle.Standard && (
                    <Grid item xs={rateToggle === RateToggle.Both ? 6 : 12}>
                        <FormNumberInput
                            value={minimumFlatRate}
                            onChange={(event) => setMinimumFlatRate(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber)}
                            label='Minimum Flat Rate'
                            name='minimumFlatRate'
                            errorText={fieldErrors.get(minimumFlatRateFieldName)}
                            error={!!fieldErrors.get(minimumFlatRateFieldName)}
                            fullWidth
                            required
                            disabled={disabled}
                            inputProps={{ min: 0 }}
                            isCurrency
                        />
                    </Grid>
                )}
            </Grid>
            <Grid item container spacing={1}>
                {rateToggle !== RateToggle.Flat && (
                    <Grid item xs={rateToggle === RateToggle.Both ? 6 : 12}>
                        <FormNumberInput
                            value={driverRate ?? 0}
                            onChange={(event) => setDriverRate(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber)}
                            label='Driver Rate'
                            name='driverRate'
                            errorText={fieldErrors.get(driverRateFieldName)}
                            error={!!fieldErrors.get(driverRateFieldName)}
                            fullWidth
                            required
                            disabled={disabled}
                            inputProps={{ min: 0 }}
                            isCurrency
                        />
                    </Grid>
                )}
                {rateToggle !== RateToggle.Standard && (
                    <Grid item xs={rateToggle === RateToggle.Both ? 6 : 12}>
                        <FormNumberInput
                            value={driverFlatRate}
                            onChange={(event) => setDriverFlatRate(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber)}
                            label='Flat Driver Rate'
                            name='minimumDriverFlatRate'
                            errorText={fieldErrors.get(driverFlatRateFieldName)}
                            error={!!fieldErrors.get(driverFlatRateFieldName)}
                            fullWidth
                            required
                            disabled={disabled}
                            inputProps={{ min: 0 }}
                            isCurrency
                        />
                    </Grid>
                )}
            </Grid>
            <Grid item container spacing={1}>
                {rateToggle !== RateToggle.Flat && (
                    <Grid item xs={rateToggle === RateToggle.Both ? 6 : 12}>
                        <FormNumberInput
                            value={brokerRate ?? 0}
                            onChange={(event) => setBrokerRate(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber)}
                            label='Broker Rate'
                            name='brokerRate'
                            errorText={fieldErrors.get(brokerRateFieldName)}
                            error={!!fieldErrors.get(brokerRateFieldName)}
                            fullWidth
                            required
                            disabled={disabled}
                            inputProps={{ min: 0 }}
                            isCurrency
                        />
                    </Grid>
                )}
                {rateToggle !== RateToggle.Standard && (
                    <Grid item xs={rateToggle === RateToggle.Both ? 6 : 12}>
                        <FormNumberInput
                            value={brokerFlatRate}
                            onChange={(event) => setBrokerFlatRate(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber)}
                            label='Flat Broker Rate'
                            name='minimumBrokerFlatRate'
                            errorText={fieldErrors.get(brokerFlatRateFieldName)}
                            error={!!fieldErrors.get(brokerFlatRateFieldName)}
                            fullWidth
                            required
                            disabled={disabled}
                            inputProps={{ min: 0 }}
                            isCurrency
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
