export const generatedApiTags = [
	'BillingRates',
	'Broker',
	'Dispatch',
	'CanceledDispatch',
	'CloudStorage',
	'Contact',
	'Customer',
	'Order',
	'Driver',
	'Document',
	'Equipment',
	'DumpLineItem',
	'DumpRate',
	'EquipmentType',
	'HourlyLineItem',
	'IncomeCode',
	'Job',
	'Quote',
	'JobNote',
	'LineItem',
	'MaterialLineItem',
	'MiscLineItem',
	'SiteHaulingLineItem',
	'Material',
	'MaterialPrice',
	'MiscSettings',
	'OrderReview',
	'OrderLineItem',
	'PriceList',
	'QuickBooks',
	'Region',
	'Site',
	'SurchargeCode',
	'TermsCode',
	'Trash',
	'TaxCode',
	'User'
]