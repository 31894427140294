import { BryntumGridProps, BryntumScheduler, BryntumSchedulerProps, BryntumSplitter } from '@bryntum/scheduler-react';
import { Link, Tooltip, Typography } from '@mui/material';
import { FC, MutableRefObject, useCallback, useEffect, useState } from 'react';
import { OrderLineItemGrid } from '../Grids/OrderLineItemGrid';
import { DispatchDto, OrderLineItemDto } from '../../../../dtos';
import { EventModel, Model } from '@bryntum/scheduler';
import { areDateTimesEqual, emptyGuid, formatDate } from '../../../../util';
import {
    useArchiveDispatchDeleteFromDatabaseByIdMutation,
    useLazyGetDispatchHaulerQuery,
    useUpdateDispatchMutation,
} from '../../../../store/generated/generatedApi';
import { useNavigate } from 'react-router-dom';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { EventEditor } from '../../Components';
import { WebhookConnector } from '../../../../Components/Webhook';
import { add, endOfDay, startOfDay } from 'date-fns';
import { HaulerManagementDispatchingViewScheduler } from './HaulerManagementDispatchingViewScheduler';
import HaulerDispatchStore from '../../Stores/DispatchEvent/DispatchEventStore';
import HaulerDispatch from '../../Stores/DispatchEvent/DispatchEventModel';

export interface IHaulerManagementDispatchingViewProps {
    date: Date;
    isEditEventOpen: boolean;
    schedulerConfig: BryntumSchedulerProps;
    schedulerRef: MutableRefObject<BryntumScheduler>;
    selectedEvent?: EventModel;
    setEditEventOpen: (isOpen: boolean) => void;
    setSelectedEvent: (event?: EventModel) => void;
    webhookConnector: WebhookConnector;
}

export const HaulerManagementDispatchingView: FC<IHaulerManagementDispatchingViewProps> = (props) => {
    const {
        date: currentDate,
        isEditEventOpen,
        schedulerConfig,
        schedulerRef,
        selectedEvent,
        setEditEventOpen,
        setSelectedEvent,
        webhookConnector,
    } = props;
    const navigate = useNavigate();
    const [updateDispatch] = useUpdateDispatchMutation();
    const [deleteDispatch] = useArchiveDispatchDeleteFromDatabaseByIdMutation();
    const [getData, { data }] = useLazyGetDispatchHaulerQuery();
    const [date, setDate] = useState<Date>(currentDate);

    const [crudManager] = useState({
        eventStore: {
            // @ts-ignore
            storeClass: HaulerDispatchStore,
            autoLoad: true,
            syncDataOnLoad: false,
        },
        assignmentStore: {
            autoLoad: true,
            syncDataOnLoad: false,
        },
        resourceStore: {
            autoLoad: true,
            syncDataOnLoad: false,
        },
    } as any);

    const loadData = useCallback(
        (date: Date) => {
            Promise.all([getData({ startDate: formatDate(date, 'yyyy-MM-dd') })]);
        },
        [getData]
    );
    useEffect(() => {
        loadData(currentDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (date !== currentDate) {
            loadData(currentDate);
            setDate(currentDate);
        }
    }, [currentDate, date, loadData]);

    useEffect(() => {
        if (!schedulerRef?.current?.instance) {
            return;
        }
        if (date) {
            const startDate = startOfDay(date);
            const endDate = endOfDay(startDate);
            schedulerRef?.current?.instance.setTimeSpan(startDate, endDate);
        }
    }, [date, schedulerRef, schedulerRef?.current?.instance]);

    useEffect(() => {
        const { events } = webhookConnector;
        if (!events) {
            return;
        }
        events((updatedDate, id) => {
            if (updatedDate === formatDate(date, 'yyyy-MM-dd')) {
                loadData(date);
                if (id === '' || id === selectedEvent?.id) {
                    setEditEventOpen(false);
                }
            }
        });
    }, [webhookConnector, date, loadData, selectedEvent?.id, setEditEventOpen]);

    const [columnsConfig] = useState({
        data: [
            {
                id: 'code',
                text: 'Code',
                width: 50,
                field: 'code',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
            },
            {
                id: 'name',
                text: 'Name',
                width: 50,
                field: 'name',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
            },
            {
                id: 'assignedEquipment',
                text: 'Assigned Equipment',
                width: 50,
                field: 'assignedEquipment',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
            },
            {
                text: 'Hire Date',
                width: 50,
                field: 'hireDate',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
            },
            {
                id: 'isScheduled',
                text: 'Scheduled',
                width: 50,
                field: 'isScheduled',
                enableCellContextMenu: false,
                readOnly: true,
                htmlEncode: false,
                renderer: ({ record }: { record: any }) => (
                    <Tooltip title={record.isScheduled ? 'Scheduled' : 'Unscheduled'}>
                        {record.isScheduled ? (
                            <CheckCircle sx={{ width: 24, height: 24, color: 'green' }} />
                        ) : (
                            <Cancel sx={{ width: 24, height: 24, color: '#A3271F' }} />
                        )}
                    </Tooltip>
                ),
                align: 'center' as 'center' | 'left' | 'right' | 'start' | 'end' | undefined, // Weird hack to resolve bryntum not recognizing the alignment value correctly
                hidden: false,
            },
            {
                id: 'haulerType',
                text: 'Hauler Type',
                width: 50,
                field: 'haulerType',
                enableCellContextMenu: false,
                readOnly: true,
                hidden: false,
            },
        ],
    });

    const getOrderLineItemFromResourceModel = useCallback((data: { record: Model }) => {
        return (data.record as any).data as OrderLineItemDto;
    }, []);

    const [gridConfig] = useState<BryntumGridProps>({
        stateId: 'haulerManagement_orderGridState',
        rowHeight: 80,
        minWidth: 300,
        cls: 'b-unplannedgrid1',
        groupFeature: {
            field: 'haulerType',
            ascending: false,
        },
        columns: {
            data: [
                {
                    id: 'customerName',
                    field: 'customerName',
                    width: 50,
                    text: 'Customer',
                    enableCellContextMenu: false,
                    readOnly: true,
                    renderer: (data: { record: Model }) => `${getOrderLineItemFromResourceModel(data).customerName}`,
                    hidden: false,
                },
                {
                    id: 'siteName',
                    field: 'siteName',
                    width: 50,
                    text: 'Site',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                    renderer: (data: { record: Model }) => `${getOrderLineItemFromResourceModel(data).siteName ?? 'N/A'}`,
                },
                {
                    id: 'regionName',
                    field: 'regionName',
                    width: 50,
                    text: 'Region',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                    renderer: (data: { record: Model }) => `${getOrderLineItemFromResourceModel(data).regionName ?? 'N/A'}`,
                },
                {
                    id: 'orderLineItemNumber',
                    field: 'orderLineItemNumber',
                    width: 50,
                    text: 'Line Item #',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: false,
                    renderer: (data: { record: Model }) =>
                        `#${getOrderLineItemFromResourceModel(data).orderLineItemNumber}/${getOrderLineItemFromResourceModel(data).order?.orderLineItemCount}`,
                    sortable(lhs: any, rhs: any) {
                        return (lhs?.data?.orderLineItem?.orderLineItemNumber ?? 'N/A') < (rhs?.data?.orderLineItem?.orderLineItemNumber ?? 'N/A') ? -1 : 1;
                    },
                },
                {
                    id: 'orderer',
                    field: 'orderer',
                    width: 150,
                    text: 'Orderer',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: false,
                    renderer: (data: { record: Model }) => `${getOrderLineItemFromResourceModel(data).orderer}`,
                },
                {
                    id: 'jobNumber',
                    field: 'jobNumber',
                    width: 50,
                    text: 'Job #',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: false,
                    renderer: (data: { record: Model }) => (
                        <Typography>
                            <Link
                                href={`/job/${getOrderLineItemFromResourceModel(data).jobId}`}
                                variant='body2'
                                onClick={() => {
                                    navigate(`/job/${getOrderLineItemFromResourceModel(data).jobId}`);
                                }}>
                                #{getOrderLineItemFromResourceModel(data).jobNumber}
                            </Link>
                        </Typography>
                    ),
                    sortable(lhs: any, rhs: any) {
                        return (lhs?.data?.jobNumber ?? 'N/A') < (rhs?.data?.jobNumber ?? 'N/A') ? -1 : 1;
                    },
                },
                {
                    id: 'orderNumber',
                    field: 'orderNumber',
                    width: 50,
                    text: 'Order #',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: false,
                    renderer: (data: { record: Model }) => (
                        <Typography>
                            <Link
                                href={`/job/${getOrderLineItemFromResourceModel(data).jobId}/order/${getOrderLineItemFromResourceModel(data).orderId}`}
                                variant='body2'
                                onClick={() => {
                                    navigate(`/job/${getOrderLineItemFromResourceModel(data).jobId}/order/${getOrderLineItemFromResourceModel(data).orderId}`);
                                }}>
                                #{getOrderLineItemFromResourceModel(data).orderNumber}
                            </Link>
                        </Typography>
                    ),
                    sortable(lhs: any, rhs: any) {
                        return (lhs?.data?.orderNumber ?? 'N/A') < (rhs?.data?.orderNumber ?? 'N/A') ? -1 : 1;
                    },
                },
                {
                    id: 'description',
                    field: 'lineItem.description',
                    width: 50,
                    text: 'Description',
                    enableCellContextMenu: false,
                    hidden: false,
                    readOnly: true,
                },
                {
                    id: 'equipmentType',
                    field: 'equipmentType.type',
                    width: 50,
                    text: 'Equipment Type',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                },
                {
                    id: 'requirements',
                    field: 'requirements',
                    width: 50,
                    text: 'Requirements',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                },
                {
                    id: 'projectName',
                    field: 'projectName',
                    width: 50,
                    text: 'Project',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                },
                {
                    id: 'jobName',
                    field: 'jobName',
                    width: 50,
                    text: 'Job',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                },
                {
                    id: 'dispatchingIntervalTimeFormatted',
                    field: 'dispatchingIntervalTimeFormatted',
                    width: 50,
                    text: 'Interval Time',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                },
                {
                    id: 'onsiteTime',
                    field: 'onsiteTime',
                    width: 50,
                    text: 'Onsite Time',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                    renderer: (data: { record: Model }) => {
                        const onsiteTime = getOrderLineItemFromResourceModel(data).onsiteTime;
                        const formattedOnsiteTime = onsiteTime ? new Date(onsiteTime)?.toLocaleTimeString([], { timeStyle: 'short' }) : 'ASAP';
                        return `${formattedOnsiteTime}`;
                    },
                    groupRenderer: ({ groupRowFor }) => {
                        return groupRowFor !== '!!novalue!!' ? new Date(groupRowFor).toLocaleTimeString([], { timeStyle: 'short' }) : 'ASAP';
                    }
                },
                {
                    id: 'yardTime',
                    field: 'yardTime',
                    width: 50,
                    text: 'Yard Time',
                    enableCellContextMenu: false,
                    readOnly: true,
                    hidden: true,
                    renderer: (data: { record: Model }) => {
                        const yardTime = getOrderLineItemFromResourceModel(data).yardTime;
                        const formattedYardTime = yardTime ? new Date(yardTime)?.toLocaleTimeString([], { timeStyle: 'short' }) : 'ASAP';
                        return `${formattedYardTime}`;
                    },
                    groupRenderer: ({ groupRowFor }) => {
                        return groupRowFor !== '!!novalue!!' ? new Date(groupRowFor).toLocaleTimeString([], { timeStyle: 'short' }) : 'ASAP';
                    }
                },
            ],
        },
        stripeFeature: true,
    });

    const createDispatchDto = useCallback(
        (data: any) => {
            // if start date shifts to the previous date, set it to midnight of the current date.
            if (data.startDate < startOfDay(currentDate)) {
                data.startDate = startOfDay(currentDate);
            }

            if (data.endDate > endOfDay(currentDate)) {
                data.endDate = endOfDay(currentDate);
            }

            if (data.endDate < data.startDate) {
                data.endDate = add(data.startDate, { hours: 1 });
            }

            // scroll to the start date of the new event
            schedulerRef.current.instance.scrollToDate(data.startDate, true);

            const dispatch = {
                id: emptyGuid,
                isActive: true,
                createdOn: new Date(),
                orderLineItemId: data.orderLineItemId,
                driverId: data.driverId,
                brokerId: data.brokerId,
                equipmentId: data.equipmentId ?? data.driver?.equipmentId ?? undefined,
                startDate: data.startDate,
                endDate: data.endDate,
                onsiteTime: data.onsiteTime,
                yardTime: data.yardTime,
                clientId: emptyGuid,
                released: data.released,
                haulToAddressLine1: data.haulToAddressLine1,
                haulToAddressLine2: data.haulToAddressLine2,
                haulToCity: data.haulToCity,
                haulToCountry: data.haulToCountry,
                haulToState: data.haulToState,
                haulToZipCode: data.haulToZipCode,
                haulFromAddressLine1: data.haulFromAddressLine1,
                haulFromAddressLine2: data.haulFromAddressLine2,
                haulFromCity: data.haulFromCity,
                haulFromCountry: data.haulFromCountry,
                haulFromState: data.haulFromState,
                haulFromZipCode: data.haulFromZipCode,
                haulToSiteName: data.haulToSiteName,
                haulToRegion: data.haulToRegion,
                haulToRegionId: data.haulToRegionId,
                haulFromSiteName: data.haulFromSiteName,
                haulFromRegion: data.haulFromRegion,
                haulFromRegionId: data.haulFromRegionId,
                driverMemo: data.driverMemo ?? data.orderLineItem?.driverMemo,
                isHaulFromAddressLocked: data.isHaulFromAddressLocked,
                isHaulToAddressLocked: data.isHaulToAddressLocked,
            } as DispatchDto;

            if (data.id !== emptyGuid) {
                dispatch.id = data.id;
                dispatch.isActive = data.isActive;
                dispatch.createdOn = data.createdOn;
                dispatch.clientId = data.clientId;
            }

            return dispatch;
        },
        [currentDate, schedulerRef]
    );

    const handleSave = useCallback(
        (data: any, originalData: any) => {
            if (
                data.id === emptyGuid ||
                !areDateTimesEqual(data.startDate, originalData.startDate) ||
                !areDateTimesEqual(data.endDate, originalData.endDate) ||
                !areDateTimesEqual(data.onsiteTime, originalData.onsiteTime) ||
                !areDateTimesEqual(data.yardTime, originalData.yardTime) ||
                data.orderLineItemId !== originalData.orderLineItemId ||
                data.driverId !== originalData.driverId ||
                data.brokerId !== originalData.brokerId ||
                data.equipmentId !== originalData.equipmentId ||
                data.released !== originalData.released ||
                data.haulToAddressLine1 !== originalData.haulToAddressLine1 ||
                data.haulToAddressLine2 !== originalData.haulToAddressLine2 ||
                data.haulToCity !== originalData.haulToCity ||
                data.haulToCountry !== originalData.haulToCountry ||
                data.haulToState !== originalData.haulToState ||
                data.haulToZipCode !== originalData.haulToZipCode ||
                data.haulFromAddressLine1 !== originalData.haulFromAddressLine1 ||
                data.haulFromAddressLine2 !== originalData.haulFromAddressLine2 ||
                data.haulFromCity !== originalData.haulFromCity ||
                data.haulFromCountry !== originalData.haulFromCountry ||
                data.haulFromState !== originalData.haulFromState ||
                data.haulFromZipCode !== originalData.haulFromZipCode ||
                data.haulToSiteName !== originalData.haulToSiteName ||
                data.haulToRegionId !== originalData.haulToRegionId ||
                data.haulFromSiteName !== originalData.haulFromSiteName ||
                data.haulFromRegionId !== originalData.haulFromRegionId ||
                data.driverMemo !== originalData.driverMemo ||
                data.isHaulFromAddressLocked !== originalData.isHaulFromAddressLocked ||
                data.isHaulToAddressLocked !== originalData.isHaulToAddressLocked
            ) {
                const dto = createDispatchDto(data);
                if (dto.brokerId || dto.driverId) {
                    updateDispatch(dto);
                }
            }
        },
        [createDispatchDto, updateDispatch]
    );

    const onEventStoreUpdate = useCallback(
        ({ source, record, changes }: { source: any; record: HaulerDispatch; changes: any }): void => {
            const newData = (record as any).data;
            const originalData = (record as any).originalData;
            if (changes.updatedOn) {
                return;
            }
            var resource = data?.resources.find((x) => x.id === record.resourceId);
            if (!resource) {
                return;
            }
            if (resource.haulerType === 'Drivers') {
                newData.driverId = record.resourceId;
                newData.brokerId = undefined;
                newData.equipmentId = newData?.equipmentId ?? newData?.driver?.equipmentId;
                newData.equipment = newData?.equipment ?? newData?.driver?.equipment;
            } else {
                newData.brokerId = record.resourceId;
                newData.driverId = undefined;
                newData.equipmentId = undefined;
                newData.equipment = undefined;
            }
            handleSave(newData, originalData);
        },
        [data, handleSave]
    );

    return (
        <>
            {isEditEventOpen && (
                <EventEditor
                    initValues={selectedEvent}
                    isHaulerView
                    handleDelete={() => {
                        if (selectedEvent) {
                            const eventStore = schedulerRef.current.instance.eventStore;
                            eventStore.remove(selectedEvent);
                            deleteDispatch({ id: selectedEvent.getData('id') });
                            setEditEventOpen(false);
                            setSelectedEvent(undefined);
                        }
                    }}
                    open={isEditEventOpen}
                    cancel={() => {
                        setEditEventOpen(false);
                        setSelectedEvent(undefined);
                    }}
                    save={() => {
                        setEditEventOpen(false);
                        setSelectedEvent(undefined);
                    }}
                />
            )}
            <div id='main'>
                <HaulerManagementDispatchingViewScheduler
                    schedulerRef={schedulerRef}
                    config={{ ...schedulerConfig }}
                    resources={data?.resources ?? []}
                    events={data?.events ?? []}
                    columns={columnsConfig}
                    crudManager={crudManager}
                    onEventStoreUpdate={onEventStoreUpdate}
                />
                <BryntumSplitter />
                <div id='unplannedContainer'>
                    <OrderLineItemGrid
                        schedulerRef={schedulerRef}
                        dataList={data?.orderLineItems ?? []}
                        config={gridConfig}
                    />
                </div>
            </div>
        </>
    );
};
