import { SelectChangeEvent } from '@mui/material';
import _ from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { RegionDto, SiteDto } from '../../../dtos';
import { countries, emptyGuid } from '../../../util';
import { IFormFieldValidationConfig, IFormProps, isNotBlank, isShorterThanMaxLength, runFieldValidation, runFormValidation } from '../../CoreLib/library';

export interface ISiteFormValues {
    code: string;
    name: string;
    company: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    isActive: boolean;
    directions: string;
    hoursOfOperation: string;
    regionId?: string;
}

const DEFAULT_SITE: SiteDto = {
    id: emptyGuid,
    code: '',
    name: '',
    company: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: countries[2], // US
    isActive: true,
    createdOn: new Date(),
    clientId: emptyGuid,
    hoursOfOperation: '',
    regionId: undefined,
};

export function useSiteForm(props: IFormProps<SiteDto>) {
    const { save, cancel, initValues } = props;
    const [formCode, setFormCode] = useState('');
    const [formName, setFormName] = useState('');
    const [formCompany, setFormCompany] = useState('');
    const [formAddressLine1, setFormAddressLine1] = useState('');
    const [formAddressLine2, setFormAddressLine2] = useState('');
    const [formCity, setFormCity] = useState('');
    const [formState, setFormState] = useState('');
    const [formZipCode, setFormZipCode] = useState('');
    const [formCountry, setFormCountry] = useState(countries[2]);
    const [formIsActive, setFormIsActive] = useState(true);
    const [formDirections, setFormDirections] = useState('');
    const [formHoursOfOperation, setFormHoursOfOperation] = useState('');
    const [formRegion, setFormRegion] = useState<RegionDto>();

    useEffect(() => {
        setFormCode(initValues?.code ?? '');
        setFormName(initValues?.name ?? '');
        setFormCompany(initValues?.company ?? '');
        setFormAddressLine1(initValues?.addressLine1 ?? '');
        setFormAddressLine2(initValues?.addressLine2 ?? '');
        setFormCity(initValues?.city ?? '');
        setFormState(initValues?.state ?? '');
        setFormZipCode(initValues?.zipCode ?? '');
        setFormCountry(initValues?.country ?? countries[2]);
        setFormIsActive(initValues?.isActive ?? true);
        setFormDirections(initValues?.directions ?? '');
        setFormHoursOfOperation(initValues?.hoursOfOperation ?? '');
        setFormRegion(initValues?.region);
    }, [initValues]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof ISiteFormValues, string>>(
        new Map([
            ['code', ''],
            ['name', ''],
            ['company', ''],
            ['addressLine1', ''],
            ['city', ''],
            ['state', ''],
            ['zipCode', ''],
            ['country', ''],
        ])
    );

    const formFieldValidators = new Map<keyof ISiteFormValues, IFormFieldValidationConfig>([
        [
            'code',
            {
                validators: [isNotBlank, isShorterThanMaxLength(10)],
                errorMessageEntityName: 'Code',
            },
        ],
        [
            'name',
            {
                validators: [isNotBlank, isShorterThanMaxLength(100)],
                errorMessageEntityName: 'Name',
            },
        ],
        [
            'company',
            {
                validators: [isShorterThanMaxLength(100)],
                errorMessageEntityName: 'Company',
            },
        ],
        [
            'addressLine1',
            {
                validators: [isNotBlank, isShorterThanMaxLength(100)],
                errorMessageEntityName: 'Address Line 1',
            },
        ],
        [
            'city',
            {
                validators: [isNotBlank, isShorterThanMaxLength(60)],
                errorMessageEntityName: 'City',
            },
        ],
        [
            'state',
            {
                validators: [isNotBlank, isShorterThanMaxLength(25)],
                errorMessageEntityName: 'State',
            },
        ],
        [
            'zipCode',
            {
                validators: [isNotBlank, isShorterThanMaxLength(12)],
                errorMessageEntityName: 'Zip Code',
            },
        ],
        [
            'country',
            {
                validators: [isNotBlank, isShorterThanMaxLength(60)],
                errorMessageEntityName: 'Country',
            },
        ],
    ]);

    const validateForm = () => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<ISiteFormValues>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    };

    const getCurrentFormValues = (): ISiteFormValues => {
        return {
            code: formCode,
            name: formName,
            company: formCompany,
            addressLine1: formAddressLine1,
            addressLine2: formAddressLine2,
            city: formCity,
            state: formState,
            zipCode: formZipCode,
            country: formCountry,
            isActive: formIsActive,
            directions: formDirections,
            hoursOfOperation: formHoursOfOperation,
            regionId: formRegion?.id,
        };
    };

    const validateField = (fieldName: keyof ISiteFormValues) => {
        const validationConfig = formFieldValidators.get(fieldName);
        if (validationConfig) {
            const formValues = getCurrentFormValues();
            const fieldValue = formValues[fieldName];
            const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

            if (errorMessage !== fieldErrors.get(fieldName)) {
                const updatedFieldErrors = _.cloneDeep(fieldErrors);
                updatedFieldErrors.set(fieldName, errorMessage);
                setFieldErrors(updatedFieldErrors);
            }
        }
    };

    const isFormDirty = () => {
        var isDirty = formIsActive !== initValues?.isActive;
        isDirty = isDirty || formCode !== initValues?.code;
        isDirty = isDirty || formName !== initValues?.name;
        isDirty = isDirty || formCompany !== initValues?.company;
        isDirty = isDirty || formAddressLine1 !== initValues?.addressLine1;
        isDirty = isDirty || formAddressLine2 !== initValues?.addressLine2;
        isDirty = isDirty || formCity !== initValues?.city;
        isDirty = isDirty || formState !== initValues?.state;
        isDirty = isDirty || formZipCode !== initValues?.zipCode;
        isDirty = isDirty || formCountry !== initValues?.country;
        isDirty = isDirty || formDirections !== initValues?.directions;
        isDirty = isDirty || formHoursOfOperation !== initValues?.hoursOfOperation;
        isDirty = isDirty || formRegion?.id !== initValues?.regionId;
        return isDirty;
    };

    const handleCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormCode(event.target.value.replaceAll(' ', ''));
    };

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormName(event.target.value);
    };

    const handleCompanyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormCompany(event.target.value);
    };
    const handleAddressLine1Change = (event: ChangeEvent<HTMLInputElement>) => {
        setFormAddressLine1(event.target.value);
    };

    const handleAddressLine2Change = (event: ChangeEvent<HTMLInputElement>) => {
        setFormAddressLine2(event.target.value);
    };

    const handleDirectionsChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormDirections(event.target.value);
    };

    const handleCityChange = (event: SelectChangeEvent<string>) => {
        setFormCity(event.target.value);
    };

    const handleStateChange = (event: SelectChangeEvent) => {
        setFormState(event.target.value);
    };

    const handleZipCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormZipCode(event.target.value);
    };

    const handleCountryChange = (event: SelectChangeEvent) => {
        setFormCountry(event.target.value);
    };

    const handleIsActiveChange = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
    };

    const handleHoursOfOperationChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormHoursOfOperation(event.target.value);
    };

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_SITE),
                ...formValues,
            };
            save(updatedUserRecord);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleCodeChange,
        handleNameChange,
        handleCompanyChange,
        handleAddressLine1Change,
        handleAddressLine2Change,
        handleCityChange,
        handleStateChange,
        handleZipCodeChange,
        handleCountryChange,
        handleIsActiveChange,
        handleDirectionsChange,
        handleHoursOfOperationChange,
        formCode,
        formName,
        formCompany,
        formAddressLine1,
        formAddressLine2,
        formCity,
        formState,
        formZipCode,
        formCountry,
        formIsActive,
        formDirections,
        formHoursOfOperation,
        formRegion,
        validateField,
        setFormAddressLine1,
        setFormCity,
        setFormCountry,
        setFormState,
        setFormZipCode,
        setFormRegion,
    };
}
