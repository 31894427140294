import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { ContactDto, CustomerDto, JobDto, LineItemDto, OrderByType, OrderDto, OrderLineItemDto } from '../../../dtos';
import { useCreateBillingRatesLineItemMutation, useGetJobByIdQuery } from '../../../store/generated/generatedApi';
import { LineItemTypes, QuoteType, emptyGuid } from '../../../util';
import { IFormFieldValidationConfig, IFormProps, createValidatorConfig, isNotBlank, runFieldValidation, runFormValidation } from '../../CoreLib/library';

export interface IOrderFormValues {
    orderNumber?: number;
    orderDate?: Date | null;
    clientId?: string;
    jobId: string;
    job?: JobDto;
    quoteId?: string;
    orderLineItems: OrderLineItemDto[];
    isActive: boolean;
    memo: string;
    poNumber: string;
    contact?: ContactDto;
    contactId?: string;
    isTaxable?: boolean;
}

export const DEFAULT_ORDER: OrderDto = {
    id: emptyGuid,
    clientId: emptyGuid,
    jobId: emptyGuid,
    quoteId: emptyGuid,
    orderNumber: undefined,
    orderDate: new Date(),
    isActive: true,
    createdOn: new Date(),
    orderLineItems: [],
    memo: '',
    poNumber: '',
    contact: undefined,
    contactId: emptyGuid,
    isTaxable: undefined,
};

const ORDER_VALIDATION_CONFIG = new Map<keyof IOrderFormValues, IFormFieldValidationConfig>([['orderDate', createValidatorConfig([isNotBlank], 'Order Date')]]);

function buildLineItemValidationConfig(lineItem: OrderLineItemDto): Map<keyof OrderLineItemDto, IFormFieldValidationConfig> {
    var validationConfig = new Map<keyof OrderLineItemDto, IFormFieldValidationConfig>();
    if (lineItem.orderBy) {
        if (lineItem.orderBy === OrderByType.Loads) {
            validationConfig.set('loadOrQuantityReq', createValidatorConfig([isNotBlank], 'Load/Quantity Req'));
        }
    } else {
        validationConfig.set('equipmentRequired', createValidatorConfig([isNotBlank], 'Equipment Required'));
    }
    return validationConfig;
}

export function useOrderForm(props: IFormProps<OrderDto>, jobId: string) {
    const { save, cancel, initValues } = props;

    const [getBillingRates] = useCreateBillingRatesLineItemMutation();

    const { data: job, isLoading: isJobLoading } = useGetJobByIdQuery({ id: jobId! });

    const [formOrderNumber] = useState<number | undefined>(initValues?.orderNumber);
    const [formIsActive, setFormIsActive] = useState(initValues?.isActive ?? true);
    const [formOrderDate] = useState<Date | null | undefined>(initValues?.orderDate ? new Date(initValues?.orderDate) : new Date());
    const [formOrderLineItems, setFormOrderLineItems] = useState<OrderLineItemDto[]>(
        initValues?.orderLineItems.map((x) => {
            return {
                ...x,
                poNumber: x.poNumber ?? job?.currentQuote?.poNumber ?? '',
            } as OrderLineItemDto;
        }) ?? []
    );
    const [formMemo, setFormMemo] = useState<string>(initValues?.memo ?? '');
    const [formPoNumber, setFormPoNumber] = useState(
        initValues?.poNumber || job?.currentQuote?.poNumber || ''
    );
    const [isChanged, setIsChanged] = useState(false);
    const [formContact, setFormContact] = useState<ContactDto | undefined>(initValues?.contact ?? job?.currentQuote?.contact);
    const [formQuote, setFormQuote] = useState(initValues?.quote ?? job?.currentQuote);
    const [isSaved, setIsSaved] = useState(false);
    const [formCustomer] = useState<CustomerDto | undefined>(job?.currentQuote?.customer);
    const [formIsTaxable, setFormIsTaxable] = useState(initValues?.isTaxable ?? formQuote?.isTaxable ?? false);

    const [isQuickAddMaterialLineItemDialogOpen, setIsQuickAddMaterialLineItemDialogOpen] = useState(false);
    const [selectedSiteHaulingLineItem, setSelectedSiteHaulingLineItem] = useState<LineItemDto | undefined>();

    const [fieldErrors, setFieldErrors] = useState<Map<keyof IOrderFormValues, string>>(new Map([['orderDate', '']]));
    const [lineItemFieldErrors, setLineItemFieldErrors] = useState<Map<keyof OrderLineItemDto, string>[]>([]);

    const createOrderLineItemErrorsMap = () => {
        return new Map<keyof OrderLineItemDto, string>([
            ['orderBy', ''],
            ['loadOrQuantityReq', ''],
            ['equipmentRequired', ''],
            ['memo', ''],
        ]);
    };

    useEffect(() => {
        if (initValues?.orderLineItems) {
            var errorMessagePlaceholders = initValues?.orderLineItems.map(() => createOrderLineItemErrorsMap());
            setLineItemFieldErrors(errorMessagePlaceholders);
        }
    }, [formOrderLineItems, initValues?.orderLineItems]);

    const getCurrentFormValues = useCallback((): IOrderFormValues => {
        return {
            jobId: jobId,
            isActive: formIsActive,
            orderNumber: formOrderNumber,
            orderDate: formOrderDate,
            orderLineItems: formOrderLineItems,
            memo: formMemo,
            poNumber: formPoNumber,
            contact: formContact,
            contactId: formContact?.id,
            quoteId: formQuote?.id,
            isTaxable: formIsTaxable,
        };
    }, [formContact, formIsActive, formIsTaxable, formMemo, formOrderDate, formOrderLineItems, formOrderNumber, formPoNumber, formQuote?.id, jobId]);

    const validateForm = useCallback(() => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<IOrderFormValues>>(formValues, ORDER_VALIDATION_CONFIG);
        setFieldErrors(validationResult.errorMessages);

        const lineItemValidationResults = formOrderLineItems.map((lineItem) =>
            runFormValidation<OrderLineItemDto>(lineItem, buildLineItemValidationConfig(lineItem))
        );
        const lineItemErrorMessages = lineItemValidationResults.map((lir) => lir.errorMessages);
        setLineItemFieldErrors(lineItemErrorMessages);

        return validationResult.isValid && lineItemValidationResults.every((lir) => lir.isValid);
    }, [formOrderLineItems, getCurrentFormValues]);

    const validateField = useCallback(
        (fieldName: keyof IOrderFormValues) => {
            const validationConfig = ORDER_VALIDATION_CONFIG.get(fieldName);
            if (validationConfig) {
                const formValues = getCurrentFormValues();
                const fieldValue = formValues[fieldName];
                const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

                if (errorMessage !== fieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(fieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setFieldErrors(updatedFieldErrors);
                }
            }
        },
        [fieldErrors, getCurrentFormValues]
    );

    const isFormDirty = useCallback(() => {
        if (isSaved) {
            return false;
        }
        var isDirty = formIsActive !== initValues?.isActive;
        isDirty = isDirty || formOrderNumber !== initValues?.orderNumber;
        isDirty = isDirty || formMemo !== initValues?.memo;
        isDirty = isDirty || formPoNumber !== (initValues?.poNumber ?? formQuote?.poNumber);
        isDirty = isDirty || isChanged;
        return isDirty;
    }, [
        formIsActive,
        formMemo,
        formOrderNumber,
        formPoNumber,
        formQuote?.poNumber,
        initValues?.isActive,
        initValues?.memo,
        initValues?.orderNumber,
        initValues?.poNumber,
        isChanged,
        isSaved,
    ]);

    const handleOrderLineItemsChange = useCallback((value: OrderLineItemDto[] | ((currentValues: OrderLineItemDto[]) => OrderLineItemDto[])) => {
        setFormOrderLineItems(value);
        setIsChanged(true);
    }, []);

    const handleIsActiveChange = useCallback((_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
        setIsChanged(true);
    }, []);

    const handleRemoveLineItem = useCallback(
        (index: number) => {
            var updatedLineErrors = _.cloneDeep(lineItemFieldErrors);
            updatedLineErrors.splice(index, 1);
            setLineItemFieldErrors(updatedLineErrors);

            var updatedLineItems = _.cloneDeep(formOrderLineItems);
            updatedLineItems.splice(index, 1);
            setFormOrderLineItems(
                updatedLineItems.map((x, idx) => {
                    // Reset line item indices on row delete
                    return {
                        ...x,
                        orderLineItemNumber: idx + 1,
                    };
                })
            );
            setIsChanged(true);
        },
        [formOrderLineItems, lineItemFieldErrors]
    );

    const handleMemoChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormMemo(event.target.value);
        setIsChanged(true);
    }, []);

    const handleContactChange = useCallback((value?: ContactDto) => {
        setFormContact(value);
        setIsChanged(true);
    }, []);

    const handleIsTaxableChange = useCallback((_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsTaxable(isChecked);
        setIsChanged(true);
    }, []);

    const [defaultErrorMessageMap] = useState(
        new Map<keyof OrderLineItemDto, string>([
            ['orderBy', ''],
            ['loadOrQuantityReq', ''],
            ['equipmentRequired', ''],
            ['memo', ''],
            ['haulToAddressLine1', ''],
            ['haulToCountry', ''],
            ['haulToCity', ''],
            ['haulToState', ''],
            ['haulToZipCode', ''],
            ['haulFromAddressLine1', ''],
            ['haulFromCountry', ''],
            ['haulFromCity', ''],
            ['haulFromState', ''],
            ['haulFromZipCode', ''],
        ])
    );

    const getHaulToAddress = useCallback(
        (lineItem: LineItemDto) => {
            switch (lineItem.type) {
                case LineItemTypes.Material:
                case LineItemTypes.SiteHauling: {
                    return {
                        addressLine1: formQuote?.addressLine1,
                        addressLine2: formQuote?.addressLine2,
                        city: formQuote?.city,
                        state: formQuote?.state,
                        zipCode: formQuote?.zipCode,
                        country: formQuote?.country,
                        region: formQuote?.region,
                        regionId: formQuote?.region?.id,
                        siteName: formQuote?.siteName,
                    };
                }
                case LineItemTypes.Dump: {
                    return {
                        addressLine1: lineItem.site?.addressLine1,
                        addressLine2: lineItem.site?.addressLine2,
                        city: lineItem.site?.city,
                        state: lineItem.site?.state,
                        zipCode: lineItem.site?.zipCode,
                        country: lineItem.site?.country,
                        region: lineItem.site?.region,
                        regionId: lineItem.site?.regionId,
                        siteName: lineItem.site?.name,
                    };
                }
                default: {
                    return {
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        country: '',
                        region: undefined,
                        regionId: undefined,
                        siteName: '',
                    };
                }
            }
        },
        [
            formQuote?.addressLine1,
            formQuote?.addressLine2,
            formQuote?.city,
            formQuote?.country,
            formQuote?.state,
            formQuote?.zipCode,
            formQuote?.region,
            formQuote?.siteName,
        ]
    );

    const getHaulFromAddress = useCallback(
        (lineItem: LineItemDto) => {
            switch (lineItem.type) {
                case LineItemTypes.Hourly:
                case LineItemTypes.Dump:
                case LineItemTypes.Misc: {
                    return {
                        addressLine1: formQuote?.addressLine1,
                        addressLine2: formQuote?.addressLine2,
                        city: formQuote?.city,
                        state: formQuote?.state,
                        zipCode: formQuote?.zipCode,
                        country: formQuote?.country,
                        region: formQuote?.region,
                        regionId: formQuote?.region?.id,
                        siteName: formQuote?.siteName,
                        isHaulFromAddressLocked: true,
                    };
                }
                case LineItemTypes.Material:
                case LineItemTypes.SiteHauling: {
                    return {
                        addressLine1: lineItem.site?.addressLine1,
                        addressLine2: lineItem.site?.addressLine2,
                        city: lineItem.site?.city,
                        state: lineItem.site?.state,
                        zipCode: lineItem.site?.zipCode,
                        country: lineItem.site?.country,
                        region: lineItem.site?.region,
                        regionId: lineItem.site?.regionId,
                        siteName: lineItem.site?.name,
                        isHaulFromAddressLocked: true,
                    };
                }
                default: {
                    return {
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        country: '',
                        region: undefined,
                        regionId: undefined,
                        siteName: '',
                        isHaulFromAddressLocked: false,
                    };
                }
            }
        },
        [
            formQuote?.addressLine1,
            formQuote?.addressLine2,
            formQuote?.city,
            formQuote?.country,
            formQuote?.state,
            formQuote?.zipCode,
            formQuote?.region,
            formQuote?.siteName,
        ]
    );

    const createNewOrderLineItem = useCallback(
        (lineItem: LineItemDto, index: number): Promise<OrderLineItemDto> => {
            var haulToAddress = getHaulToAddress(lineItem);
            var haulFromAddress = getHaulFromAddress(lineItem);
            let billingRates;
            return getBillingRates(lineItem).then((result) => {
                if ((result as any).data) {
                    billingRates = (result as any).data;
                }

                return {
                    id: emptyGuid,
                    isActive: true,
                    createdOn: new Date(),
                    equipmentType: lineItem.equipmentType,
                    equipmentTypeId: lineItem.equipmentTypeId,
                    lineItemId: lineItem.id,
                    lineItem: lineItem,
                    orderBy: OrderByType.Quantity,
                    loadOrQuantityReq: 0,
                    equipmentRequired: 0,
                    memo: '',
                    driverMemo: lineItem.driverMemo ?? '',
                    dispatchMemo: '',
                    arrivalTime: undefined,
                    yardTime: undefined,
                    onsiteTime: undefined,
                    dispatchingIntervalTime: undefined,
                    orderLineItemNumber: index,
                    poNumber: formPoNumber ?? formQuote?.poNumber ?? '',
                    haulToAddressLine1: haulToAddress.addressLine1,
                    haulToAddressLine2: haulToAddress.addressLine2,
                    haulToCity: haulToAddress.city,
                    haulToState: haulToAddress.state,
                    haulToZipCode: haulToAddress.zipCode,
                    haulToCountry: haulToAddress.country,
                    haulToRegion: haulToAddress.region,
                    haulToRegionId: haulToAddress.regionId,
                    haulToSiteName: haulToAddress.siteName,
                    haulFromAddressLine1: haulFromAddress.addressLine1,
                    haulFromAddressLine2: haulFromAddress.addressLine2,
                    haulFromCity: haulFromAddress.city,
                    haulFromState: haulFromAddress.state,
                    haulFromZipCode: haulFromAddress.zipCode,
                    haulFromCountry: haulFromAddress.country,
                    haulFromRegion: haulFromAddress.region,
                    haulFromRegionId: haulFromAddress.regionId,
                    haulFromSiteName: haulFromAddress.siteName,
                    billingRates: billingRates,
                };
            });
        },
        [formPoNumber, formQuote?.poNumber, getBillingRates, getHaulFromAddress, getHaulToAddress]
    );

    const handleAddNewLineItem = useCallback(
        async (lineItem: LineItemDto) => {
            if (lineItem.type === LineItemTypes.SiteHauling) {
                if (formQuote?.type === QuoteType.Standard) {
                    setIsQuickAddMaterialLineItemDialogOpen(true);
                    setSelectedSiteHaulingLineItem(lineItem);
                }
            } else {
                var updatedLineErrors = _.cloneDeep(lineItemFieldErrors);
                var newErrorMessageMap = defaultErrorMessageMap;
                updatedLineErrors.push(newErrorMessageMap);
                setLineItemFieldErrors(updatedLineErrors);

                var updatedLineItems = _.cloneDeep(formOrderLineItems);
                const newOrderLineItem = await createNewOrderLineItem(lineItem, updatedLineItems.length + 1);
                updatedLineItems.push(newOrderLineItem);
                setFormOrderLineItems(updatedLineItems);
                setIsChanged(true);
            }
        },
        [createNewOrderLineItem, defaultErrorMessageMap, formOrderLineItems, formQuote?.type, lineItemFieldErrors]
    );

    const handleAddNewLineItems = useCallback(
        async (lineItems: LineItemDto[] | undefined) => {
            if (!lineItems) {
                return;
            }
            const filteredLineItems = lineItems.filter((x) => x.type !== LineItemTypes.SiteHauling); // Site Hauling line items are handled separately
            var updatedLineErrors = _.cloneDeep(lineItemFieldErrors);
            for (let index = 0; index < filteredLineItems.length; index++) {
                var newErrorMessageMap = defaultErrorMessageMap;
                updatedLineErrors.push(newErrorMessageMap);
            }
            setLineItemFieldErrors(updatedLineErrors);

            var updatedLineItems = _.cloneDeep(formOrderLineItems);
            for (let index = 0; index < filteredLineItems.length; index++) {
                const newOrderLineItem = await createNewOrderLineItem(filteredLineItems[index], updatedLineItems.length + 1);
                updatedLineItems.push(newOrderLineItem);
            }

            setFormOrderLineItems(updatedLineItems);
            setIsChanged(true);
        },
        [createNewOrderLineItem, defaultErrorMessageMap, formOrderLineItems, lineItemFieldErrors]
    );

    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_ORDER),
                ...formValues,
            };
            save(updatedUserRecord as any);
            setIsChanged(false);
            setIsSaved(true);
        }
    }, [getCurrentFormValues, initValues, save, validateForm]);

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleOrderLineItemsChange,
        handleIsActiveChange,
        handleAddNewLineItem,
        handleAddNewLineItems,
        handleRemoveLineItem,
        handleMemoChange,
        handleContactChange,
        handleIsTaxableChange,
        setFormPoNumber,
        formContact,
        formIsActive,
        formOrderNumber,
        formOrderDate,
        formOrderLineItems,
        formMemo,
        formPoNumber,
        formQuote,
        formCustomer,
        formIsTaxable,
        setFormQuote,
        validateField,
        job,
        isJobLoading,
        lineItemFieldErrors,
        isQuickAddMaterialLineItemDialogOpen,
        selectedSiteHaulingLineItem,
        setIsQuickAddMaterialLineItemDialogOpen,
    };
}
