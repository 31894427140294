import { FormControl, FormHelperText, FormLabel, Grid, MenuItem, OutlinedInput, Select } from '@mui/material';
import { FC } from 'react';
import { useGetCustomerQuery } from '../../../store/generated/generatedApi';
import { usePrompt } from '../../../Views';
import { SiteSelect } from '../../CommonInputs';
import { ApiError, FormInput, LoadingIndicator } from '../../CoreLib/library';
import { ContactFormManager } from './useContactForm';

export interface IContactFormProps {
    contactFormManager: ContactFormManager;
    isLoading?: boolean;
}

export const ContactForm: FC<IContactFormProps> = ({ contactFormManager, isLoading }) => {
    const {
        fieldErrors,
        validateField,
        formFirstName,
        formLastName,
        formEmail,
        formTitle,
        formLandlinePhone,
        formCellPhone,
        formFax,
        formMemo,
        handleFirstNameChange,
        handleLastNameChange,
        handleEmailChange,
        handleTitleChange,
        handleLandlinePhoneChange,
        handleCellPhoneChange,
        handleFaxChange,
        handleMemoChange,
        customerId,
        siteId,
        isChanged,
    } = contactFormManager;

    const {
        data: customers,
        isLoading: customersLoading,
        isError: customersLoadError,
        refetch: customersRefetch,
    } = useGetCustomerQuery({ page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });

    usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', isChanged);

    if (isLoading || customersLoading) {
        return <LoadingIndicator />;
    }

    if (customersLoadError) {
        return <ApiError onReloadClick={customersRefetch} />;
    }

    return (
        <>
            <Grid item container direction='row' spacing={3}>
                <Grid item xs={3}>
                    <FormControl disabled fullWidth required>
                        {customerId ? (
                            <>
                                <FormLabel>Customer</FormLabel>
                                <Select value={customerId}>
                                    {customers?.pageResults.map((c) => (
                                        <MenuItem key={c?.id} value={c?.id}>
                                            {c?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : (
                            <SiteSelect
                                selectedSiteId={siteId ?? ''}
                                handleSelectedSiteChange={() => {}}
                                required
                                errorMessage={fieldErrors.get('siteId')}
                                disabled
                            />
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl error={!!fieldErrors.get('firstName')} fullWidth required disabled={isLoading}>
                        <FormLabel>First Name</FormLabel>
                        <OutlinedInput value={formFirstName} onChange={handleFirstNameChange} onBlur={() => validateField('firstName')} />
                        <FormHelperText>{fieldErrors.get('firstName')}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl error={!!fieldErrors.get('lastName')} fullWidth required disabled={isLoading}>
                        <FormLabel>Last Name</FormLabel>
                        <OutlinedInput value={formLastName} onChange={handleLastNameChange} onBlur={() => validateField('lastName')} />
                        <FormHelperText>{fieldErrors.get('lastName')}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth disabled={isLoading}>
                        <FormLabel>Title</FormLabel>
                        <OutlinedInput value={formTitle} onChange={handleTitleChange} />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item container direction='row' spacing={3}>
                <Grid item xs={3}>
                    <FormControl error={!!fieldErrors.get('email')} fullWidth disabled={isLoading}>
                        <FormLabel>Email</FormLabel>
                        <OutlinedInput value={formEmail} onChange={handleEmailChange} onBlur={() => validateField('email')} />
                        <FormHelperText>{fieldErrors.get('email')}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormInput
                        value={formLandlinePhone}
                        onChange={handleLandlinePhoneChange}
                        label='Landline Phone'
                        name='landlinePhone'
                        errorText={fieldErrors.get('landlinePhone')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormInput
                        value={formCellPhone}
                        onChange={handleCellPhoneChange}
                        label='Cell Phone'
                        name='cellPhone'
                        errorText={fieldErrors.get('cellPhone')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormInput value={formFax} onChange={handleFaxChange} label='Fax' name='fax' errorText={fieldErrors.get('fax')} fullWidth />
                </Grid>
            </Grid>
            <Grid item container direction='row' spacing={3}>
                <Grid item xs={12}>
                    <FormInput value={formMemo} onChange={handleMemoChange} label='Memo' name='memo' errorText={fieldErrors.get('memo')} fullWidth rows={3} />
                </Grid>
            </Grid>
        </>
    );
};
