import { SelectChangeEvent } from '@mui/material';
import _, { } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
    EquipmentTypeDto,
    MaterialDto,
    MaterialLineItemDto,
    MaterialPriceCostDto,
    MaterialPriceDto,
    QuoteDto,
    RateToggle,
    SiteDto,
    SiteHaulingLineItemDto,
} from '../../../../../dtos';
import { unitsOfMeasure, emptyGuid, UnitOfMeasure, QuoteType } from '../../../../../util';
import {
    IFormProps,
    IFormFieldValidationConfig,
    isNotBlank,
    isShorterThanMaxLength,
    runFormValidation,
    runFieldValidation,
    isGreaterThanOrEqualTo,
} from '../../../../CoreLib/library';

const isValidUnitOfMeasure = (value: string): value is UnitOfMeasure => {
    return unitsOfMeasure.includes(value);
};

export interface IMaterialLineItemFormValues {
    cost?: number;
    equipmentTypeId: string;
    isActive: boolean;
    isBilledToCustomer: boolean;
    materialId: string;
    materialPriceId?: string;
    materialPriceCostId?: string;
    newHaulingLineItemDriverRate?: number;
    newHaulingLineItemRate?: number;
    newHaulingLineItemBrokerRate?: number;
    newHaulingLineItemMinimumFlatRate?: number;
    newHaulingLineItemDriverMinimumFlatRate?: number;
    newHaulingLineItemBrokerMinimumFlatRate?: number;
    priceCode: string;
    quantity?: number;
    quoteId: string;
    additionalRate: number;
    driverMemo?: string;
    rate?: number;
    siteId: string;
    total?: number;
    unitOfMeasure: string;
    rateToggle: RateToggle;
}

export const DEFAULT_MATERIAL_LINE_ITEM: MaterialLineItemDto = {
    id: emptyGuid,
    quoteId: '',
    siteId: '',
    equipmentTypeId: '',
    materialId: '',
    materialPriceId: '',
    materialPriceCostId: '',
    additionalRate: undefined,
    driverMemo: '',
    rate: 0,
    quantity: 0,
    isBilledToCustomer: false,
    priceCode: '',
    cost: 0,
    newHaulingLineItemRate: 0,
    newHaulingLineItemDriverRate: 0,
    newHaulingLineItemBrokerRate: 0,
    newHaulingLineItemMinimumFlatRate: undefined,
    newHaulingLineItemDriverMinimumFlatRate: undefined,
    newHaulingLineItemBrokerMinimumFlatRate: undefined,
    unitOfMeasure: 'Tons',
    isActive: true,
    createdOn: new Date(),
    rateToggle: RateToggle.Standard,
};

export function useMaterialLineItemForm(props: IFormProps<MaterialLineItemDto>) {
    const { save, cancel, initValues } = props;
    const [formQuote, setFormQuote] = useState<QuoteDto | undefined>(initValues?.quote);
    const [formEquipmentType, setFormEquipmentType] = useState<EquipmentTypeDto | undefined>(initValues?.equipmentType);
    const [formSite, setFormSite] = useState<SiteDto | undefined>(initValues?.site);
    const [formMaterialPrice, setFormMaterialPrice] = useState<MaterialPriceDto | undefined>(initValues?.materialPrice);
    const [formMaterialPriceCost, setFormMaterialPriceCost] = useState<MaterialPriceCostDto | undefined>(initValues?.materialPriceCost);
    const [formMaterial, setFormMaterial] = useState<MaterialDto | undefined>(initValues?.material);
    const [formUnitOfMeasure, setFormUnitOfMeasure] = useState<UnitOfMeasure>((initValues?.unitOfMeasure as UnitOfMeasure) ?? 'Tons');
    const [formAdditionalHaulingRate, setFormAdditionalHaulingRate] = useState<number | undefined>(initValues?.additionalRate);
    const [formDriverMemo, setFormDriverMemo] = useState<string | undefined>(initValues?.driverMemo);
    const [formRate, setFormRate] = useState<number | undefined>(initValues?.rate);
    const [formQuantity, setFormQuantity] = useState<number | undefined>(initValues?.quantity);
    const [formIsBilledToCustomer, setFormIsBilledToCustomer] = useState<boolean>(initValues?.isBilledToCustomer ?? false);
    const [formPriceCode, setFormPriceCode] = useState<string>(initValues?.priceCode ?? '');
    const [formCost, setFormCost] = useState<number | undefined>(initValues?.cost);
    const [formTotal, setFormTotal] = useState<number | undefined>(initValues?.salesPrice);
    const [formNewHaulingLineItemRate, setFormNewHaulingLineItemRate] = useState<number | undefined>();
    const [formNewHaulingLineItemDriverRate, setFormNewHaulingLineItemDriverRate] = useState<number | undefined>();
    const [formNewHaulingLineItemBrokerRate, setFormNewHaulingLineItemBrokerRate] = useState<number | undefined>();
    const [formNewHaulingLineItemMinimumFlatRate, setFormHaulingLineItemMinimumFlatRateChange] = useState<number | undefined>();
    const [formNewHaulingLineItemDriverMinimumFlatRate, setFormHaulingLineItemDriverMinimumFlatRateChange] = useState<number | undefined>();
    const [formNewHaulingLineItemBrokerMinimumFlatRate, setFormHaulingLineItemBrokerMinimumFlatRateChange] = useState<number | undefined>();
    const [formRateToggle, setFormRateToggle] = useState<RateToggle>(initValues?.rateToggle ?? RateToggle.Standard);
    const [formIsActive, setFormIsActive] = useState(true);

    const [currentSiteHaulingLineItem, setCurrentSiteHaulingLineItem] = useState<SiteHaulingLineItemDto>();

    const isFobQuote = useMemo(() => {
        return formQuote?.type === QuoteType.FOB;
    }, [formQuote?.type]);

    useEffect(() => {
        setFormTotal((formQuantity ?? 0) * ((formRate ?? 0) + (formAdditionalHaulingRate ?? 0)));
    }, [formAdditionalHaulingRate, formQuantity, formRate]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof IMaterialLineItemFormValues, string>>(
        new Map([
            ['quoteId', ''],
            ['equipmentTypeId', ''],
            ['siteId', ''],
            ['rate', ''],
            ['additionalRate', ''],
            ['driverMemo', ''],
            ['unitOfMeasure', ''],
            ['materialPriceId', ''],
            ['materialId', ''],
            ['quantity', ''],
            ['total', ''],
            ['newHaulingLineItemRate', ''],
            ['newHaulingLineItemDriverRate', ''],
            ['newHaulingLineItemBrokerRate', ''],
            ['newHaulingLineItemMinimumFlatRate', ''],
            ['newHaulingLineItemDriverMinimumFlatRate', ''],
            ['newHaulingLineItemBrokerMinimumFlatRate', ''],
            ['priceCode', ''],
            ['cost', ''],
        ])
    );

    const doesNotRequireNewHaulingLineItem = useCallback(() => {
        if (isFobQuote) {
            return true;
        }
        return currentSiteHaulingLineItem !== undefined;
    }, [currentSiteHaulingLineItem, isFobQuote]);

    const formFieldValidators = useMemo(
        () =>
            new Map<keyof IMaterialLineItemFormValues, IFormFieldValidationConfig>([
                [
                    'quoteId',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Quote',
                    },
                ],
                [
                    'equipmentTypeId',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Equipment Type',
                    },
                ],
                [
                    'siteId',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Site',
                    },
                ],
                [
                    'materialId',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Material',
                    },
                ],
                [
                    'materialPriceId',
                    {
                        validators: formIsBilledToCustomer ? [] : [isNotBlank],
                        errorMessageEntityName: 'Material Price',
                    },
                ],
                [
                    'total',
                    {
                        validators: [isNotBlank, isGreaterThanOrEqualTo(0)],
                        errorMessageEntityName: 'Total',
                    },
                ],
                [
                    'rate',
                    {
                        validators: [isNotBlank, isGreaterThanOrEqualTo(0)],
                        errorMessageEntityName: 'Rate',
                    },
                ],
                [
                    'additionalRate',
                    {
                        validators: [isGreaterThanOrEqualTo(0)],
                        errorMessageEntityName: 'Additional Hauling Rate',
                    },
                ],
                [
                    'quantity',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Quantity',
                    },
                ],
                [
                    'cost',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Cost',
                    },
                ],
                [
                    'newHaulingLineItemRate',
                    {
                        validators: !doesNotRequireNewHaulingLineItem() && formRateToggle !== RateToggle.Flat ? [isNotBlank] : [],
                        errorMessageEntityName: 'Hauling Rate',
                    },
                ],
                [
                    'newHaulingLineItemDriverRate',
                    {
                        validators: !doesNotRequireNewHaulingLineItem() && formRateToggle !== RateToggle.Flat ? [isNotBlank] : [],
                        errorMessageEntityName: 'Driver Rate',
                    },
                ],
                [
                    'newHaulingLineItemBrokerRate',
                    {
                        validators: !doesNotRequireNewHaulingLineItem() && formRateToggle !== RateToggle.Flat ? [isNotBlank] : [],
                        errorMessageEntityName: 'Broker Rate',
                    },
                ],
                [
                    'newHaulingLineItemMinimumFlatRate',
                    {
                        validators: !doesNotRequireNewHaulingLineItem() && formRateToggle !== RateToggle.Standard ? [isNotBlank] : [],
                        errorMessageEntityName: 'Minimum Flat Rate',
                    },
                ],
                [
                    'newHaulingLineItemDriverMinimumFlatRate',
                    {
                        validators: !doesNotRequireNewHaulingLineItem() && formRateToggle !== RateToggle.Standard ? [isNotBlank] : [],
                        errorMessageEntityName: 'Driver Minimum Flat Rate',
                    },
                ],
                [
                    'newHaulingLineItemBrokerMinimumFlatRate',
                    {
                        validators: !doesNotRequireNewHaulingLineItem() && formRateToggle !== RateToggle.Standard ? [isNotBlank] : [],
                        errorMessageEntityName: 'Broker Minimum Flat Rate',
                    },
                ],
                [
                    'unitOfMeasure',
                    {
                        validators: [isNotBlank, isShorterThanMaxLength(20)],
                        errorMessageEntityName: 'Unit Of Measure',
                    },
                ],
                [
                    'priceCode',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Price Code',
                    },
                ],
            ]),
        [doesNotRequireNewHaulingLineItem, formIsBilledToCustomer, formRateToggle]
    );
    const getCurrentFormValues = useCallback((): IMaterialLineItemFormValues => {
        return {
            quoteId: formQuote?.id ?? '',
            siteId: formSite?.id ?? '',
            equipmentTypeId: formEquipmentType?.id ?? '',
            materialPriceId: formMaterialPrice?.id,
            materialPriceCostId: formMaterialPriceCost?.id,
            materialId: formMaterial?.id ?? '',
            additionalRate: formAdditionalHaulingRate ?? 0,
            driverMemo: formDriverMemo ?? '',
            rate: formRate,
            quantity: formQuantity ?? 1,
            isBilledToCustomer: formIsBilledToCustomer ?? false,
            priceCode: formPriceCode ?? '',
            cost: formCost,
            total: formTotal,
            newHaulingLineItemRate: formNewHaulingLineItemRate,
            newHaulingLineItemDriverRate: formNewHaulingLineItemDriverRate,
            newHaulingLineItemBrokerRate: formNewHaulingLineItemBrokerRate,
            newHaulingLineItemMinimumFlatRate: formNewHaulingLineItemMinimumFlatRate,
            newHaulingLineItemDriverMinimumFlatRate: formNewHaulingLineItemDriverMinimumFlatRate,
            newHaulingLineItemBrokerMinimumFlatRate: formNewHaulingLineItemBrokerMinimumFlatRate,
            unitOfMeasure: formUnitOfMeasure ?? '',
            isActive: formIsActive,
            rateToggle: formRateToggle,
        };
    }, [
        formAdditionalHaulingRate,
        formDriverMemo,
        formCost,
        formEquipmentType?.id,
        formIsActive,
        formIsBilledToCustomer,
        formMaterial?.id,
        formMaterialPrice?.id,
        formMaterialPriceCost?.id,
        formNewHaulingLineItemDriverMinimumFlatRate,
        formNewHaulingLineItemDriverRate,
        formNewHaulingLineItemMinimumFlatRate,
        formNewHaulingLineItemRate,
        formNewHaulingLineItemBrokerRate,
        formNewHaulingLineItemBrokerMinimumFlatRate,
        formPriceCode,
        formQuantity,
        formQuote?.id,
        formRate,
        formSite?.id,
        formTotal,
        formUnitOfMeasure,
        formRateToggle,
    ]);

    const validateForm = useCallback(() => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<IMaterialLineItemFormValues>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [formFieldValidators, getCurrentFormValues]);

    const validateField = useCallback(
        (fieldName: keyof IMaterialLineItemFormValues) => {
            const validationConfig = formFieldValidators.get(fieldName);
            if (validationConfig) {
                const formValues = getCurrentFormValues();
                const fieldValue = formValues[fieldName];
                const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

                if (errorMessage !== fieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(fieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setFieldErrors(updatedFieldErrors);
                }
            }
        },
        [fieldErrors, formFieldValidators, getCurrentFormValues]
    );

    const isFormDirty = useCallback(() => {
        var isDirty = formIsActive !== initValues?.isActive;
        isDirty = isDirty || formCost !== initValues?.cost;
        isDirty = isDirty || formEquipmentType?.id !== initValues?.equipmentType?.id;
        isDirty = isDirty || formIsBilledToCustomer !== initValues?.isBilledToCustomer;
        isDirty = isDirty || formMaterialPrice?.id !== initValues?.materialPrice?.id;
        isDirty = isDirty || formMaterialPriceCost?.id !== initValues?.materialPriceCost?.id;
        isDirty = isDirty || formPriceCode !== initValues?.priceCode;
        isDirty = isDirty || formQuantity !== initValues?.quantity;
        isDirty = isDirty || formQuote?.id !== initValues?.quote?.id;
        isDirty = isDirty || formAdditionalHaulingRate !== initValues?.additionalRate;
        isDirty = isDirty || formDriverMemo !== initValues?.driverMemo;
        isDirty = isDirty || formRate !== initValues?.rate;
        isDirty = isDirty || formSite?.id !== initValues?.site?.id;
        isDirty = isDirty || formUnitOfMeasure !== initValues?.unitOfMeasure;
        isDirty = isDirty || formRateToggle !== initValues?.rateToggle;

        return isDirty;
    }, [
        formIsActive,
        initValues?.isActive,
        initValues?.cost,
        initValues?.equipmentType?.id,
        initValues?.isBilledToCustomer,
        initValues?.materialPrice?.id,
        initValues?.materialPriceCost?.id,
        initValues?.priceCode,
        initValues?.quantity,
        initValues?.quote?.id,
        initValues?.additionalRate,
        initValues?.driverMemo,
        initValues?.rate,
        initValues?.site?.id,
        initValues?.unitOfMeasure,
        initValues?.rateToggle,
        formCost,
        formEquipmentType?.id,
        formIsBilledToCustomer,
        formMaterialPrice?.id,
        formMaterialPriceCost?.id,
        formPriceCode,
        formQuantity,
        formQuote?.id,
        formAdditionalHaulingRate,
        formDriverMemo,
        formRate,
        formSite?.id,
        formUnitOfMeasure,
        formRateToggle,
    ]);

    const handleSetFormAdditionalHaulingRate = useCallback(
        (unitOfMeasure) => {
            if (formMaterial) {
                switch (unitOfMeasure) {
                    case 'Tons':
                        setFormAdditionalHaulingRate(formMaterial?.ratePerTon);
                        break;
                    case 'Loads':
                        setFormAdditionalHaulingRate(formMaterial?.ratePerLoad);
                        break;
                    case 'Yards':
                        setFormAdditionalHaulingRate(formMaterial?.ratePerYard);
                        break;
                    default:
                        setFormAdditionalHaulingRate(undefined);
                        break;
                }
            }
        },
        [formMaterial]
    );

    const handleCostChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (!isNaN(parseFloat(event.target.value))) {
            setFormCost(parseFloat(event.target.value));
        } else {
            setFormCost(undefined);
        }
    }, []);

    const handleAdditionalHaulingRateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormAdditionalHaulingRate(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    }, []);

    const handleDriverMemoChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormDriverMemo(event.target.value ?? '');
    }, []);

    const handleEquipmentTypeChange = useCallback((equipmentType?: EquipmentTypeDto) => {
        setFormEquipmentType(equipmentType);
    }, []);

    const handleMaterialChange = useCallback((material?: MaterialDto) => {
        setFormMaterial(material);
        setFormMaterialPrice(undefined);
    }, []);

    const handleMaterialPriceChange = useCallback(
        (materialPrice?: MaterialPriceDto) => {
            setFormMaterialPrice(materialPrice);
            setFormMaterialPriceCost(materialPrice?.currentMaterialPriceCost);
            setFormSite(materialPrice?.site);
            setFormUnitOfMeasure(materialPrice?.currentUnitOfMeasure as UnitOfMeasure);
            setFormCost(materialPrice?.currentCost);
            setFormQuantity(1);
            handleSetFormAdditionalHaulingRate(materialPrice?.material?.defaultUnitOfMeasure);
            switch (formPriceCode) {
                case 'A':
                    setFormRate(materialPrice?.currentPriceA);
                    break;
                case 'B':
                    setFormRate(materialPrice?.currentPriceB);
                    break;
                case 'C':
                    setFormRate(materialPrice?.currentPriceC);
                    break;
                case 'D':
                    setFormRate(materialPrice?.currentPriceD);
                    break;
                case 'E':
                    setFormRate(materialPrice?.currentPriceE);
                    break;
                default:
                    setFormPriceCode('C');
                    setFormRate(materialPrice?.currentPriceC);
                    break;
            }
        },
        [formPriceCode, handleSetFormAdditionalHaulingRate]
    );

    const handleIsBilledToCustomerChange = useCallback((_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsBilledToCustomer(isChecked);
        if (isChecked) {
            setFormMaterialPrice(undefined);
            setFormMaterialPriceCost(undefined);
            setFormPriceCode('Custom');
            setFormCost(0);
            setFormRate(0);
            setFormQuantity(1);
            setFormUnitOfMeasure('Tons');
            setFormAdditionalHaulingRate(undefined);
        }
    }, []);

    const handleHaulingDriverRateChange = useCallback((value?: number) => {
        setFormNewHaulingLineItemDriverRate(value);
    }, []);

    const handleHaulingHaulingRateChange = useCallback((value?: number) => {
        setFormNewHaulingLineItemRate(value);
    }, []);

    const handleHaulingBrokerRateChange = useCallback((value?: number) => {
        setFormNewHaulingLineItemBrokerRate(value);
    }, []);

    const handleHaulingMinimumFlatRateChange = useCallback((value?: number) => {
        setFormHaulingLineItemMinimumFlatRateChange(value);
    }, []);

    const handleHaulingDriverMinimumFlatRateChange = useCallback((value?: number) => {
        setFormHaulingLineItemDriverMinimumFlatRateChange(value);
    }, []);

    const handleHaulingBrokerMinimumFlatRateChange = useCallback((value?: number) => {
        setFormHaulingLineItemBrokerMinimumFlatRateChange(value);
    }, []);

    const handleRateToggleChange = useCallback((rateToggle: RateToggle) => {
        setFormRateToggle(rateToggle);
    }, []);

    const getCurrentRate = useCallback(
        (priceCode: string) => {
            switch (priceCode) {
                case 'A':
                    return formMaterialPrice?.currentPriceA;
                case 'B':
                    return formMaterialPrice?.currentPriceB;
                case 'C':
                    return formMaterialPrice?.currentPriceC;
                case 'D':
                    return formMaterialPrice?.currentPriceD;
                case 'E':
                    return formMaterialPrice?.currentPriceE;
                default:
                    return 0;
            }
        },
        [
            formMaterialPrice?.currentPriceA,
            formMaterialPrice?.currentPriceB,
            formMaterialPrice?.currentPriceC,
            formMaterialPrice?.currentPriceD,
            formMaterialPrice?.currentPriceE,
        ]
    );

    const handlePriceCodeChange = useCallback(
        (event: SelectChangeEvent<string>) => {
            const priceCode = event.target.value;
            setFormPriceCode(priceCode);
            if (priceCode !== 'Custom') {
                if (priceCode === '') {
                    setFormCost(undefined);
                } else {
                    setFormCost(formMaterialPrice?.currentCost);
                }
            }

            setFormRate(getCurrentRate(priceCode));
        },
        [formMaterialPrice?.currentCost, getCurrentRate]
    );

    const handleQuantityChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormQuantity(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    }, []);

    const handleQuoteChange = useCallback((quote: QuoteDto) => {
        setFormQuote(quote);
    }, []);

    const handleRateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormRate(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    }, []);

    const handleSiteChange = useCallback((site?: SiteDto) => {
        setFormSite(site);
        setFormMaterialPrice(undefined);
        setFormMaterialPriceCost(undefined);
        setFormRate(0);
    }, []);

    const handleTotalChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormTotal(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    }, []);

    const handleUnitOfMeasureChange = useCallback(
        (value?: string) => {
            if (isValidUnitOfMeasure(value ?? '')) {
                setFormUnitOfMeasure(value as UnitOfMeasure);
                handleSetFormAdditionalHaulingRate(value);
            } else {
                console.error('Attempted to set unit of measure to invalid value.');
            }
        },
        [handleSetFormAdditionalHaulingRate]
    );

    const handleIsActiveChange = useCallback((_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
    }, []);

    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_MATERIAL_LINE_ITEM),
                ...formValues,
            };
            save(updatedUserRecord);
        }
    }, [getCurrentFormValues, initValues, save, validateForm]);

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleCostChange,
        handleEquipmentTypeChange,
        handleHaulingDriverRateChange,
        handleHaulingHaulingRateChange,
        handleHaulingBrokerRateChange,
        handleIsActiveChange,
        handleIsBilledToCustomerChange,
        handleMaterialChange,
        handleMaterialPriceChange,
        handlePriceCodeChange,
        handleQuantityChange,
        handleQuoteChange,
        handleRateChange,
        handleSiteChange,
        handleTotalChange,
        handleUnitOfMeasureChange,
        handleAdditionalHaulingRateChange,
        handleDriverMemoChange,
        handleHaulingMinimumFlatRateChange,
        handleHaulingDriverMinimumFlatRateChange,
        handleHaulingBrokerMinimumFlatRateChange,
        setCurrentSiteHaulingLineItem,
        handleRateToggleChange,
        currentSiteHaulingLineItem,
        formQuote,
        formSite,
        formEquipmentType,
        formRate,
        formQuantity,
        formIsBilledToCustomer,
        formUnitOfMeasure,
        formIsActive,
        formMaterial,
        formMaterialPrice,
        formPriceCode,
        formCost,
        formTotal,
        formNewHaulingLineItemRate,
        formNewHaulingLineItemDriverRate,
        formNewHaulingLineItemBrokerRate,
        formNewHaulingLineItemMinimumFlatRate,
        formNewHaulingLineItemDriverMinimumFlatRate,
        formNewHaulingLineItemBrokerMinimumFlatRate,
        formAdditionalHaulingRate,
        formDriverMemo,
        formRateToggle,
        validateField,
        isFobQuote,
        initValues,
        getCurrentRate,
    };
}
