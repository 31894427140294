import { useAuth0 } from '@auth0/auth0-react';
import { Article, Assignment, ControlPointDuplicate, DownloadForOffline, ListAlt, ThumbUp } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import {
    Box,
    Checkbox,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    Link,
    MenuItem,
    Paper,
    Select,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CloneQuoteDialog, usePrompt } from '../../../Views';
import { QuoteLineItemsView } from '../../../Views/Quote/LineItems';
import { ContactDto, CustomerDto, LineItemDto, QuoteDto, RegionDto, UserDto } from '../../../dtos';
import { AppraisalType } from '../../../dtos/generated/AppraisalType';
import {
    useArchiveQuoteDeleteByIdMutation,
    useCreateOrderQuickCreateByIdMutation,
    useCreateQuoteCloneMutation,
    useCreateQuotePdfMutation,
    useGetContactQuery,
    useGetCustomerQuery,
    useGetJobQuery,
    useGetRegionQuery,
    useGetUserQuery,
    useLazyGetUserAuth0UserIdByIdQuery,
    useUpdateQuoteAcceptMutation
} from '../../../store/generated/generatedApi';
import { LineItemTypes, countries, emptyGuid, quoteTypes, usStates } from '../../../util';
import { AddressLookup, DatePicker, SiteSelect } from '../../CommonInputs';
import { SlimFormHeader } from '../../Core/SlimFormHeader';
import {
    FormInput,
    FormNumberInput,
    FormSection,
    IEntityAutocomplete,
    IFormProps,
    LoadingIndicator,
    useFailedActionSnackbar,
    useSuccessfulActionSnackbar,
} from '../../CoreLib/library';
import { ContactQuickAddButton, CustomerQuickAddButton } from '../../QuickAdds';
import { AcceptQuoteLineItemsDialog } from './AcceptQuoteLineItemsDialog';
import { useQuoteForm } from './useQuoteForm';

export interface IQuoteFormProps extends IFormProps<QuoteDto> {
    customer?: CustomerDto;
}

export const QuoteForm: FC<IQuoteFormProps> = (props) => {
    const { isLoading, initValues, customer } = props;
    const { tab, type, appraisalType } = useParams();
    const [selectedTab, setSelectedTab] = useState(tab ?? '');
    const { user, isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    const [isAcceptLineItemsDialogOpen, setIsAcceptLineItemsDialogOpen] = useState(false);

    const {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        formIsActive,
        formQuoteNumber,
        formStatus,
        formQuoteDate,
        formExpirationDate,
        formCustomer,
        formContact,
        formEffectiveOn,
        formPoNumber,
        formProjectName,
        formAddressLine1,
        formAddressLine2,
        formCity,
        formState,
        formZipCode,
        formCountry,
        formMiles,
        formMinutes,
        formToOrFromJob,
        formSalesPerson,
        formMemo,
        formType,
        formAppraisalType,
        formJobName,
        formSiteName,
        formRegion,
        formSite,
        formIsTaxable,
        handleIsActiveChange,
        handleQuoteNumberChange,
        handleQuoteDateChange,
        handleExpirationDateChange,
        handleCustomerChange,
        handleContactChange,
        handleEffectiveOnDateChange,
        handlePoNumberChange,
        handleProjectNameChange,
        handleAddressLine1Change,
        handleAddressLine2Change,
        handleCityChange,
        handleStateChange,
        handleZipCodeChange,
        handleCountryChange,
        handleMilesChange,
        handleMinutesChange,
        handleToOrFromJobChange,
        handleSalesPersonChange,
        handleMemoChange,
        handleFormTypeChange,
        handleSiteNameChange,
        handleRegionChange,
        handleSiteChange,
        handleIsTaxableChange,
        setFormType,
        setFormAppraisalType,
        handleJobNameChange,
        setFormSalesPerson,
        quoteLabel,
    } = useQuoteForm(props);

    usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', isFormDirty());

    const [getUserData, { data: currentUser }] = useLazyGetUserAuth0UserIdByIdQuery();

    const { data: customers, isLoading: customersLoading } = useGetCustomerQuery({
        searchText: '',
        sortKey: 'NAME',
        page: 0,
        pageSize: 100000,
        sortAsc: true,
        includeInactive: false,
    });
    const { data: contacts, isLoading: contactsLoading } = useGetContactQuery({
        customerId: formCustomer?.id ?? '',
        searchText: '',
        sortKey: 'CONTACT_NAME',
        page: 0,
        pageSize: 100000,
        sortAsc: true,
        includeInactive: false,
    });
    const { data: jobs } = useGetJobQuery({
        quoteId: initValues?.id,
        searchText: '',
        sortKey: 'JOB_NUMBER',
        page: 0,
        pageSize: 100000,
        sortAsc: true,
        includeInactive: false,
    });
    const { data: users, isLoading: usersLoading } = useGetUserQuery({
        searchText: '',
        sortKey: 'FIRST_NAME',
        page: 0,
        pageSize: 100000,
        sortAsc: true,
        includeInactive: false,
    });

    const { currentData: regions } = useGetRegionQuery({ searchText: '', sortKey: 'NAME', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });

    const [getQuotePdf, { isSuccess, isError, error: pdfGenerationError, isLoading: pdfLoading, reset: resetCreatePDf }] = useCreateQuotePdfMutation();
    const [acceptQuote, { isSuccess: isQuoteAcceptSuccess, isError: isQuoteAcceptError, isLoading: isAcceptingQuote, reset: resetAcceptQuote }] =
        useUpdateQuoteAcceptMutation();
    const [isCloneQuoteDialogOpen, setIsCloneQuoteDialogOpen] = useState(false);
    const [
        duplicateQuote,
        {
            data: duplicatedQuote,
            isSuccess: isQuoteDuplicateSuccess,
            isError: isQuoteDuplicateSuccessError,
            isLoading: isDuplicatingQuote,
            reset: resetDuplicateQuote,
        },
    ] = useCreateQuoteCloneMutation();
    useSuccessfulActionSnackbar('cloned', 'quote', isQuoteDuplicateSuccess, resetDuplicateQuote);
    useFailedActionSnackbar('clone', 'quote', isQuoteDuplicateSuccessError, resetDuplicateQuote);

    const [
        quickCreateOrder,
        { data: order, isSuccess: quickCreateSuccess, isError: quickCreateError, isLoading: quickCreateOrderLoading, reset: resetQuickCreate },
    ] = useCreateOrderQuickCreateByIdMutation();
    useSuccessfulActionSnackbar('created', 'order', quickCreateSuccess, resetQuickCreate);
    useFailedActionSnackbar('create', 'order', quickCreateError, resetQuickCreate);

    useSuccessfulActionSnackbar('created', 'quote PDF', isSuccess, resetCreatePDf);
    useFailedActionSnackbar('create', 'quote PDF', isError, resetCreatePDf, pdfGenerationError);
    useSuccessfulActionSnackbar('accepted', 'quote', isQuoteAcceptSuccess, resetAcceptQuote);
    useFailedActionSnackbar('accept', 'quote', isQuoteAcceptError, resetAcceptQuote);

    useEffect(() => {
        if (isQuoteAcceptSuccess) {
            if (jobs) {
                if (jobs.totalQueryResults > 0) {
                    navigate(`/job/${jobs?.pageResults[0].id}`);
                }
            }
        }
    }, [isQuoteAcceptSuccess, jobs, navigate]);

    useEffect(() => {
        if (quickCreateSuccess && order) {
            window.open(`/job/${order?.jobId}/order/${order.id}`, '_blank');
        }
    }, [isSuccess, quickCreateSuccess, order]);

    useEffect(() => {
        if (isQuoteDuplicateSuccess && duplicatedQuote) {
            resetDuplicateQuote();
            window.open(`/quote/${duplicatedQuote?.id}`, '_blank');
        }
    }, [isQuoteDuplicateSuccess, duplicatedQuote, resetDuplicateQuote]);

    useEffect(() => {
        if (type) {
            setFormType(type);
        }

        if (appraisalType) {
            const parsedInt = parseInt(appraisalType);
            if (!isNaN(parsedInt)) {
                setFormAppraisalType(parsedInt as AppraisalType);
            }
        }
    }, [type, setFormType, appraisalType, setFormAppraisalType]);

    useEffect(() => {
        if (currentUser && !formSalesPerson) {
            setFormSalesPerson(currentUser);
        }
        if (!currentUser && isAuthenticated && (!initValues?.id || initValues?.id === emptyGuid)) {
            if (user?.sub) {
                getUserData({ id: user?.sub });
            }
        }
    }, [user, getUserData, isAuthenticated, initValues?.id, currentUser, formSalesPerson, setFormSalesPerson]);

    const displayName = useMemo(() => {
        const jobName = formJobName ? `${formJobName} - ` : '';
        return (
            initValues?.id
                ? `${jobName}${formCustomer?.name} (${initValues?.appraisalType ? 'Estimate' : 'Quote'} #${initValues?.quoteNumber})`
                : `New ${quoteLabel}`
        ).trim();
    }, [formCustomer?.name, formJobName, initValues?.appraisalType, initValues?.id, initValues?.quoteNumber, quoteLabel]);

    const currentId = useMemo(() => {
        return props.initValues?.id;
    }, [props.initValues?.id]);

    const generatePdf = useCallback(() => {
        if (!pdfLoading && currentId) {
            getQuotePdf({ id: currentId }).then((response: any) => {
                if (response?.data) {
                    window.open(response?.data.url, '_blank');
                }
            });
        }
    }, [currentId, getQuotePdf, pdfLoading]);

    const handleAcceptClicked = useCallback(() => {
        const hasAmendedLineItems = initValues?.hasAmendedLineItems;
        if (!isAcceptingQuote && currentId) {
            if (!hasAmendedLineItems) {
                acceptQuote({ params: { id: currentId }, payload: [] });
            } else {
                setIsAcceptLineItemsDialogOpen(true);
            }
        }
    }, [initValues?.hasAmendedLineItems, isAcceptingQuote, currentId, acceptQuote]);

    const handleCloneQuote = useCallback(
        (effectiveOn: Date, priceIncreasePercentage?: number) => {
            setIsCloneQuoteDialogOpen(false);
            if (initValues?.id) {
                duplicateQuote({ id: initValues.id, effectiveOn: effectiveOn, priceIncreasePercentage: priceIncreasePercentage });
            }
        },
        [duplicateQuote, initValues?.id]
    );

    const isAccepted = useMemo(() => {
        return !!props.initValues?.acceptedOn;
    }, [props.initValues]);

    const handleQuickInputOrder = useCallback(() => {
        if (initValues?.jobId) {
            quickCreateOrder({ jobId: initValues?.jobId });
        }
    }, [initValues?.jobId, quickCreateOrder]);

    const AdditionalHeaderItems = useMemo(() => {
        return (
            <>
                <Tooltip title={`Accept`}>
                    {!isAcceptingQuote ? (
                        <Box>
                            <IconButton onClick={handleAcceptClicked} disabled={!initValues || isAccepted || isAcceptingQuote}>
                                <ThumbUp />
                            </IconButton>
                        </Box>
                    ) : (
                        <CircularProgress />
                    )}
                </Tooltip>
                {currentId && (
                    <Tooltip title={`Clone`}>
                        <span>
                            <Box>
                                <IconButton
                                    onClick={() => {
                                        if (initValues?.jobId) {
                                            setIsCloneQuoteDialogOpen(true);
                                        }
                                    }}
                                    disabled={isLoading || isDuplicatingQuote || initValues?.clonedQuoteId !== null}>
                                    <ControlPointDuplicate />
                                </IconButton>
                            </Box>
                        </span>
                    </Tooltip>
                )}
                {formAppraisalType === AppraisalType.Quote && (
                    <Tooltip title='Download PDF'>
                        {!pdfLoading ? (
                            <Box>
                                <IconButton onClick={generatePdf} disabled={!initValues || pdfLoading}>
                                    <DownloadForOffline />
                                </IconButton>
                            </Box>
                        ) : (
                            <CircularProgress />
                        )}
                    </Tooltip>
                )}
                <Divider flexItem orientation='vertical' sx={{ mx: 1 }} />
                <Tooltip title={`Quick Input Order`}>
                    {!isAcceptingQuote ? (
                        <Box>
                            <IconButton onClick={handleQuickInputOrder} disabled={!initValues || quickCreateOrderLoading || !initValues?.isCurrentForJob}>
                                <ListAlt />
                            </IconButton>
                        </Box>
                    ) : (
                        <CircularProgress />
                    )}
                </Tooltip>
                <Tooltip title={`View Job`}>
                    {!isAcceptingQuote ? (
                        <Box>
                            <IconButton
                                onClick={() => {
                                    navigate(`/job/${initValues?.jobId}`);
                                }}
                                disabled={!initValues}>
                                <Article />
                            </IconButton>
                        </Box>
                    ) : (
                        <CircularProgress />
                    )}
                </Tooltip>
            </>
        );
    }, [
        isAcceptingQuote,
        handleAcceptClicked,
        initValues,
        isAccepted,
        currentId,
        isLoading,
        isDuplicatingQuote,
        formAppraisalType,
        pdfLoading,
        generatePdf,
        handleQuickInputOrder,
        quickCreateOrderLoading,
        navigate,
    ]);

    const AdditionalMenuItems = useMemo(() => {
        const items = [
            {
                name: 'Accept Quote',
                onClick: handleAcceptClicked,
                disabled: !props.initValues || isAccepted || isAcceptingQuote,
            },
        ];

        if (formAppraisalType === AppraisalType.Quote) {
            items.push({
                name: 'Download PDF',
                onClick: generatePdf,
                disabled: !props.initValues || pdfLoading,
            });
        }
        return items;
    }, [formAppraisalType, handleAcceptClicked, props.initValues, isAccepted, isAcceptingQuote, generatePdf, pdfLoading]);

    const handleConfirmAcceptQuoteLineItems = useCallback(
        (lineItems: LineItemDto[]) => {
            setIsAcceptLineItemsDialogOpen(false);
            if (currentId) {
                const lineItemIds = lineItems.map((x) => x.id);
                acceptQuote({ params: { id: currentId }, payload: lineItemIds });
            }
        },
        [acceptQuote, currentId]
    );

    const hasMaterialLineItem = useMemo(() => {
        return initValues?.lineItems?.some((x) => x.type === LineItemTypes.Material) ?? false;
    }, [initValues?.lineItems]);

    const handleNewCustomerAdded = useCallback(
        (newCustomer: CustomerDto) => {
            handleCustomerChange(newCustomer);
        },
        [handleCustomerChange]
    );

    const handleNewContactAdded = useCallback(
        (newContact: ContactDto) => {
            handleContactChange(newContact);
        },
        [handleContactChange]
    );

    if (isLoading || customersLoading || contactsLoading || usersLoading) {
        return <LoadingIndicator />;
    }

    return (
        <>
            {formEffectiveOn && (
                <CloneQuoteDialog
                    open={isCloneQuoteDialogOpen}
                    onConfirm={handleCloneQuote}
                    onClose={() => setIsCloneQuoteDialogOpen(false)}
                    quoteEffectiveOn={formEffectiveOn}
                />
            )}
            {initValues && (
                <AcceptQuoteLineItemsDialog
                    open={isAcceptLineItemsDialogOpen}
                    onClose={() => setIsAcceptLineItemsDialogOpen(false)}
                    onConfirm={(selectedLineItems) => handleConfirmAcceptQuoteLineItems(selectedLineItems)}
                    quote={initValues}
                />
            )}
            <SlimFormHeader
                objectName={quoteLabel}
                permissionsTypeName={'quote'}
                icon={<Assignment />}
                title={displayName}
                breadcrumbs={
                    customer
                        ? [
                            { label: 'Home', navLink: '/' },
                            { label: 'Customers', navLink: '/customers' },
                            { label: customer?.name, navLink: `/customer/${customer?.id}/dashboard` },
                        ]
                        : [
                            { label: 'Home', navLink: '/' },
                            { label: `Quotes`, navLink: '/quotes' },
                        ]
                }
                isActive={formIsActive}
                handleIsActiveChange={handleIsActiveChange}
                id={currentId}
                isLoading={isLoading}
                isFormDirty={isFormDirty}
                handleCancel={handleCancel}
                handleSave={handleSave}
                entityNameSingular={'quote'}
                deleteMutation={useArchiveQuoteDeleteByIdMutation}
                additionalHeaderItems={AdditionalHeaderItems}
                additionalMenuItems={AdditionalMenuItems}
            />
            <Grid container direction='column' spacing={2}>
                {initValues?.id && !initValues?.acceptedOn && jobs?.totalQueryResults && (
                    <Grid item mx={2}>
                        <Paper square variant='outlined'>
                            {jobs.pageResults.map((x) => {
                                return (
                                    <Grid key={x.id} container direction='row' justifyContent='center' p={2}>
                                        <Typography>
                                            <Link
                                                href={`/job/${x?.id}`}
                                                variant='body2'
                                                onClick={() => {
                                                    navigate(`/job/${x?.id}`);
                                                }}>
                                                Job #{x.jobNumber}
                                            </Link>{' '}
                                            is pending this {quoteLabel}'s approval.
                                        </Typography>
                                    </Grid>
                                );
                            })}
                        </Paper>
                    </Grid>
                )}
                <Grid item mx={2}>
                    <FormSection>
                        <TabContext value={selectedTab}>
                            <Box sx={{ borderBottom: '1px solid black' }}>
                                <Tabs
                                    value={selectedTab}
                                    onChange={(e, value) => setSelectedTab(value)}
                                    variant='fullWidth'
                                    textColor='primary'
                                    indicatorColor='primary'
                                    sx={{ width: initValues?.id !== undefined ? '100%' : '50%' }}>
                                    <Tab label={'GENERAL'} key={0} value={''} />
                                    {initValues?.id !== undefined && <Tab label={'Line Items'} key={1} value={'lineItems'} />}
                                </Tabs>
                            </Box>
                            <TabPanel value={''}>
                                <Grid item container direction='column' spacing={3}>
                                    <Grid item container direction='row' spacing={3}>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <FormNumberInput
                                                value={formQuoteNumber}
                                                onChange={handleQuoteNumberChange}
                                                label={`${quoteLabel} #`}
                                                name='customerNumber'
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <FormControl error={!!fieldErrors.get('type')} fullWidth required disabled={initValues?.id !== undefined}>
                                                <FormLabel>{quoteLabel} Type</FormLabel>
                                                <Select value={formType} onChange={handleFormTypeChange}>
                                                    {quoteTypes.map((type) => (
                                                        <MenuItem key={type} value={type}>
                                                            {type}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>{fieldErrors.get('type')}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <FormInput value={formStatus} onChange={() => { }} label={`${quoteLabel} Status`} fullWidth disabled required />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <DatePicker
                                                value={formQuoteDate}
                                                onChange={handleQuoteDateChange}
                                                error={!!fieldErrors.get('quoteDate')}
                                                label={`${quoteLabel} Date`}
                                                disabled={isAccepted}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <DatePicker
                                                value={formEffectiveOn}
                                                onChange={handleEffectiveOnDateChange}
                                                error={!!fieldErrors.get('effectiveOn')}
                                                label={`Effective On Date`}
                                                disabled={isAccepted || hasMaterialLineItem}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <DatePicker
                                                value={formExpirationDate}
                                                onChange={handleExpirationDateChange}
                                                error={!!fieldErrors.get('expirationDate')}
                                                label={`Expiration Date`}
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction='row' spacing={3} alignItems='center'>
                                        <Grid item xs={12} sm={6} md={3} display='flex'>
                                            <FormControl
                                                error={!!fieldErrors.get('customerId')}
                                                fullWidth
                                                required
                                                disabled={customer !== undefined || isAccepted}>
                                                <FormLabel>Customer</FormLabel>
                                                <IEntityAutocomplete
                                                    options={customers?.pageResults}
                                                    onChange={(e, value) => {
                                                        handleCustomerChange(value ?? null);
                                                    }}
                                                    value={formCustomer}
                                                    getOptionLabel={(option: CustomerDto) => `${option.name}`}
                                                    isLoading={customersLoading}
                                                    disabled={customer !== undefined || isAccepted}
                                                    error={!!fieldErrors.get('customerId')}
                                                />
                                                <FormHelperText>{fieldErrors.get('customerId')}</FormHelperText>
                                            </FormControl>
                                            {!isAccepted && (
                                                <Box pt={3.5}>
                                                    <CustomerQuickAddButton onAdd={handleNewCustomerAdded} />
                                                </Box>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3} display='flex'>
                                            <FormControl fullWidth disabled={!formCustomer || isAccepted}>
                                                <FormLabel>Contact</FormLabel>
                                                <IEntityAutocomplete
                                                    options={contacts?.pageResults}
                                                    onChange={(e, value) => {
                                                        handleContactChange(value ?? null);
                                                    }}
                                                    value={formContact}
                                                    getOptionLabel={(option: ContactDto) =>
                                                        option.firstName +
                                                        ' ' +
                                                        option.lastName +
                                                        (option.title ? ' - ' + option.title : '') +
                                                        (option.cellPhone ? ' - ' + option.cellPhone : '')
                                                    }
                                                    isLoading={contactsLoading}
                                                    disabled={!formCustomer || isAccepted}
                                                />
                                            </FormControl>
                                            {!isAccepted && (
                                                <Box pt={3.5}>
                                                    <ContactQuickAddButton
                                                        onAdd={handleNewContactAdded}
                                                        customerId={formCustomer?.id}
                                                        disabled={!formCustomer?.id}
                                                    />
                                                </Box>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <FormControl fullWidth disabled={isAccepted}>
                                                <FormLabel>Salesperson</FormLabel>
                                                <IEntityAutocomplete
                                                    options={users?.pageResults}
                                                    onChange={(e, value) => {
                                                        handleSalesPersonChange(value ?? null);
                                                    }}
                                                    value={formSalesPerson}
                                                    getOptionLabel={(option: UserDto) => `${option.firstName} ${option.lastName}`}
                                                    isLoading={usersLoading}
                                                    disabled={isAccepted}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <FormInput value={formPoNumber} onChange={handlePoNumberChange} label='PO Number' fullWidth disabled={isAccepted} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction='row'>
                                        <Grid item>
                                            <Typography variant='h2'>Project</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction='row' spacing={3} alignItems='center'>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <FormInput
                                                value={formProjectName}
                                                onChange={handleProjectNameChange}
                                                label='Project Name'
                                                fullWidth
                                                disabled={isAccepted}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <FormInput
                                                value={formJobName}
                                                onChange={(event) => handleJobNameChange(event.target.value)}
                                                label='Job Name'
                                                fullWidth
                                                disabled={isAccepted}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction='row' spacing={3}>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <AddressLookup
                                                handleAddressLine1Change={handleAddressLine1Change}
                                                handleCityChange={handleCityChange}
                                                handleCountryChange={handleCountryChange}
                                                handleStateChange={handleStateChange}
                                                handleZipCodeChange={handleZipCodeChange}
                                                disabled={isAccepted}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <SiteSelect
                                                selectedSiteId={formSite?.id ?? ''}
                                                handleSelectedSiteChange={handleSiteChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <FormInput
                                                value={formSiteName}
                                                onChange={(event) => handleSiteNameChange(event)}
                                                label='Site Name'
                                                fullWidth
                                                disabled={isAccepted}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <FormControl fullWidth>
                                                <FormLabel>Region</FormLabel>
                                                <IEntityAutocomplete
                                                    options={regions?.pageResults ?? []}
                                                    onChange={(_e, value) => {
                                                        handleRegionChange?.(value);
                                                    }}
                                                    value={formRegion}
                                                    getOptionLabel={(option: RegionDto) => option.name}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction='row' spacing={3}>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <FormInput
                                                value={formAddressLine1}
                                                onChange={(e) => handleAddressLine1Change(e.target.value)}
                                                label='Address Line 1'
                                                name='addressLine1'
                                                errorText={fieldErrors.get('addressLine1')}
                                                fullWidth
                                                required
                                                disabled={isAccepted}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <FormInput
                                                value={formAddressLine2}
                                                onChange={(e) => handleAddressLine2Change(e.target.value)}
                                                label='Address Line 2'
                                                name='addressLine2'
                                                fullWidth
                                                disabled={isAccepted}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <FormInput
                                                value={formCity}
                                                onChange={(e) => handleCityChange(e.target.value)}
                                                label='City'
                                                name='city'
                                                errorText={fieldErrors.get('city')}
                                                fullWidth
                                                required
                                                disabled={isAccepted}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <FormControl error={!!fieldErrors.get('state')} fullWidth required disabled={isAccepted}>
                                                <FormLabel>State</FormLabel>
                                                <Select value={formState} onChange={(e) => handleStateChange(e.target.value)}>
                                                    {usStates.map((usState) => (
                                                        <MenuItem key={usState.abbreviation} value={usState.abbreviation}>
                                                            {usState.name}
                                                        </MenuItem>
                                                    ))}
                                                    <MenuItem value='' key='noState'>-</MenuItem>
                                                </Select>
                                                <FormHelperText>{fieldErrors.get('state')}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <FormInput
                                                value={formZipCode}
                                                onChange={(e) => handleZipCodeChange(e.target.value)}
                                                label='Zip Code'
                                                name='zipCode'
                                                errorText={fieldErrors.get('zipCode')}
                                                fullWidth
                                                required
                                                disabled={isAccepted}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction='row' spacing={3}>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <FormControl error={!!fieldErrors.get('country')} fullWidth required disabled={isAccepted}>
                                                <FormLabel>Country</FormLabel>
                                                <Select value={formCountry} onChange={(e) => handleCountryChange(e.target.value)}>
                                                    {countries.map((country) => (
                                                        <MenuItem key={country} value={country}>
                                                            {country}
                                                        </MenuItem>
                                                    ))}
                                                    <MenuItem value='' key='noCountry'>-</MenuItem>
                                                </Select>
                                                <FormHelperText>{fieldErrors.get('country')}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction='row' spacing={3} alignItems='center'>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <FormNumberInput
                                                value={formMiles}
                                                name='miles'
                                                onChange={handleMilesChange}
                                                label='Miles'
                                                fullWidth
                                                inputProps={{ min: 0 }}
                                                disabled={isAccepted}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <FormNumberInput
                                                value={formMinutes}
                                                name='minutes'
                                                onChange={handleMinutesChange}
                                                label='Minutes'
                                                fullWidth
                                                inputProps={{ min: 0 }}
                                                disabled={isAccepted}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <FormInput
                                                value={formToOrFromJob}
                                                onChange={handleToOrFromJobChange}
                                                label='To or from Job'
                                                name='toOrFromJob'
                                                fullWidth
                                                disabled={isAccepted}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <FormControl fullWidth>
                                                <FormControlLabel control={<Checkbox checked={formIsTaxable} onChange={handleIsTaxableChange} />} label='Is Taxable' />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction='row' spacing={3}>
                                        <Grid item xs={12}>
                                            <FormInput value={formMemo} onChange={handleMemoChange} label='Memo' fullWidth rows={3} disabled={isAccepted} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            {initValues && initValues?.id !== emptyGuid && (
                                <TabPanel value={'lineItems'}>
                                    <QuoteLineItemsView quote={initValues} />
                                </TabPanel>
                            )}
                        </TabContext>
                    </FormSection>
                </Grid>
            </Grid>
        </>
    );
};
