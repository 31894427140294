import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { CustomerDto, JobDto } from '../../../dtos';
import { emptyGuid } from '../../../util';
import { IFormFieldValidationConfig, IFormProps, isNotBlank, runFieldValidation, runFormValidation } from '../../CoreLib/library';

export interface IJobFormValues {
    quoteId: string;
    isActive: boolean;
}

export const DEFAULT_JOB: JobDto = {
    id: emptyGuid,
    clientId: emptyGuid,
    quotes: [],
    isActive: true,
    createdOn: new Date(),
};

export function useJobForm(props: IFormProps<JobDto>) {
    const { save, cancel, initValues } = props;
    const [formQuoteId, setFormQuoteId] = useState('');
    const [formIsActive, setFormIsActive] = useState(true);

    useEffect(() => {
        setFormQuoteId(initValues?.currentQuote?.id ?? '');
        setFormIsActive(initValues?.isActive ?? true);
    }, [initValues]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof IJobFormValues, string>>(new Map([['quoteId', '']]));

    const formFieldValidators = useMemo(
        () =>
            new Map<keyof IJobFormValues, IFormFieldValidationConfig>([
                [
                    'quoteId',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Quote',
                    },
                ],
            ]),
        []
    );

    const getCurrentFormValues = useCallback((): IJobFormValues => {
        return {
            quoteId: formQuoteId,
            isActive: formIsActive,
        };
    }, [formIsActive, formQuoteId]);

    const validateForm = useCallback(() => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<IJobFormValues>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [formFieldValidators, getCurrentFormValues]);

    const validateField = useCallback(
        (fieldName: keyof IJobFormValues) => {
            const validationConfig = formFieldValidators.get(fieldName);
            if (validationConfig) {
                const formValues = getCurrentFormValues();
                const fieldValue = formValues[fieldName];
                const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

                if (errorMessage !== fieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(fieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setFieldErrors(updatedFieldErrors);
                }
            }
        },
        [fieldErrors, formFieldValidators, getCurrentFormValues]
    );

    const isFormDirty = useCallback(() => {
        var isDirty = formIsActive !== initValues?.isActive;
        isDirty = isDirty || formQuoteId !== initValues?.currentQuote?.id;

        return isDirty;
    }, [formIsActive, formQuoteId, initValues?.currentQuote?.id, initValues?.isActive]);

    const handleCustomerChange = useCallback((newCustomerValue?: CustomerDto) => {
        if (!newCustomerValue) {
            setFormQuoteId('');
        } else {
            setFormQuoteId(newCustomerValue.id);
        }
    }, []);

    const handleIsActiveChange = useCallback((_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
    }, []);

    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_JOB),
                ...formValues,
            };
            save(updatedUserRecord);
        }
    }, [getCurrentFormValues, initValues, save, validateForm]);

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleCustomerChange,
        handleIsActiveChange,
        formQuoteId,
        formIsActive,
        validateField,
    };
}
