import { Grid, Button, Badge } from '@mui/material';
import { DatePicker, PickersDay } from '@mui/x-date-pickers';
import { add, sub } from 'date-fns';
import { FC, useEffect, useState } from 'react';

export interface IDateScrollerProps {
    date: Date;
    updateDate: (updatedDate: Date) => void;
    fullWidth?: boolean;
    daysWithData?: string[];
    handleOnMonthChange?: (newMonth: Date) => void;
}

export const DateScroller: FC<IDateScrollerProps> = ({ date, updateDate, fullWidth, daysWithData, handleOnMonthChange }) => {
    const [highlightedDays, setHighlightedDays] = useState(daysWithData ?? []);
    useEffect(() => {
        setHighlightedDays(daysWithData ?? []);
    }, [daysWithData]);

    const Day = (props) => {
        const { day, outsideCurrentMonth, highlightedDays, ...other } = props;

        const isHighlighted = highlightedDays.includes(day.getDate());

        if (!isHighlighted || outsideCurrentMonth) {
            return <PickersDay day={day} outsideCurrentMonth={outsideCurrentMonth} {...other} />;
        }

        return (
            <Badge key={day.toString()} color='secondary' variant='dot' overlap='circular'>
                <PickersDay day={day} outsideCurrentMonth={outsideCurrentMonth} {...other} />
            </Badge>
        );
    };

    return (
        <>
            <Grid item>
                <Button
                    onClick={() => {
                        updateDate(sub(date, { days: 1 }));
                    }}>
                    {'<'}
                </Button>
            </Grid>
            <Grid item flexGrow={fullWidth ? 1 : undefined}>
                <DatePicker
                    value={date}
                    onChange={(newDate) => {
                        if (newDate) {
                            updateDate(new Date(newDate));
                        }
                    }}
                    slots={{
                        day: Day,
                    }}
                    slotProps={{
                        day: {
                            highlightedDays,
                        } as any,
                    }}
                    onMonthChange={(newMonth) => {
                        if (handleOnMonthChange) {
                            handleOnMonthChange(newMonth);
                        }
                    }}
                />
            </Grid>
            <Grid item>
                <Button
                    onClick={() => {
                        updateDate(add(date, { days: 1 }));
                    }}>
                    {'>'}
                </Button>
            </Grid>
        </>
    );
};
